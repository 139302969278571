import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class NovoBancoParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          preParserConfigs: {
            separatorsDistanceModifier: 0.8,
            pageSeparatorsLengthPx: 27,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Data',
                    },
                    prevWord: {
                      dateFormat: 'DD.MM.YYYY',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'Valor',
                    },
                    prevWord: {
                      eq: '(Euros)',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Data',
                      },
                      prevWord: {
                        dateFormat: 'DD.MM.YYYY',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'TOTAL',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        dateFormat: 'DD.MM.YY',
                      },
                      nextWord: {
                        eq: 'SALDO',
                      },
                    },
                  ],
                  to: [
                    {
                      word: {
                        isNum: true,
                        replace: [
                          {
                            from: '.',
                            to: '',
                          },
                          {
                            from: ',',
                            to: '.',
                          },
                        ],
                      },
                      prevWord: {
                        eq: 'ANTERIOR',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  isNum: true,
                  replace: [
                    {
                      from: '.',
                      to: '',
                    },
                    {
                      from: ',',
                      to: '.',
                    },
                  ],
                },
                {
                  in: ',',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Data', 0],
                dateFormat: 'DD.MM.YY',
              },
              comment: {
                column: ['Descritivo', 2],
              },
              debit: {
                column: ['Débito', 3],
              },
              credit: {
                column: ['Crédito', 4],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Data Operação entre',
              },
            ],
          ],
          proceedCaseConfig: {
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Empresa',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Conta',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              credit: {
                column: ['Credito', 9],
              },
              debit: {
                column: ['Debito', 8],
              },
              date: {
                column: ['Dt Op', 3],
                dateFormat: 'YYYY-MM-DD',
              },
              comment: {
                column: ['Descricao', 6],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Data Operação entre',
              },
            ],
          ],
          proceedCaseConfig: {
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Empresa',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Conta',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              credit: {
                column: ['Credito', 9],
              },
              debit: {
                column: ['Debito', 8],
              },
              date: {
                column: ['Dt Op', 3],
                dateFormat: 'YYYY-MM-DD',
              },
              comment: {
                column: ['Descricao', 6],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Empresa',
              },
            ],
          ],
          proceedCaseConfig: {
            fields: {
              credit: {
                column: ['Credito', 9],
              },
              debit: {
                column: ['Debito', 8],
              },
              date: {
                column: ['Dt Op', 3],
                dateFormat: 'YYYY-MM-DD',
              },
              comment: {
                column: ['Descricao', 6],
              },
            },
          },
        },
      },
    ],
  };
}
