import { BAS1CxlsParser } from '@finmap/import-parsers/1-c-bas-xls-parser';
import { AbankImportParser } from '@finmap/import-parsers/abank-import-parser';
import { AlfaBankImportParser } from '@finmap/import-parsers/alfabank-xlsx';
import { BankPekaoImportParser } from '@finmap/import-parsers/bank-pekao-import-parser';
import { BankPivdennyImportParser } from '@finmap/import-parsers/bank-pivdenny-import-parser';
import { BSIBankImportParser } from '@finmap/import-parsers/bsi-bank-import-parser';
import { CenterCreditBankImportParser } from '@finmap/import-parsers/centercredit-pdf-parser';
import { CreditAgricoleImportParser } from '@finmap/import-parsers/credit-agricole-import-parser';
import { EcoCenterBankPdfParser } from '@finmap/import-parsers/eco-center-pdf-parser';
import { FinlogXlsxParser } from '@finmap/import-parsers/finlog-xlsx-parser';
import { Finmap1XlsxParser } from '@finmap/import-parsers/finmap1-xlsx-parser';
import { FintabloXlsxParser } from '@finmap/import-parsers/fintablo-xlsx-parser';
import { ForteBankPdfParser } from '@finmap/import-parsers/fortebank-pdf-parser';
import { HalykBankImportParser } from '@finmap/import-parsers/halyk-pdf-parser';
import { INGBankPLNImportParser } from '@finmap/import-parsers/ingbank-pln-pdf-parser';
import { JusanbankImportParser } from '@finmap/import-parsers/jusanbank-pdf-parser';
import { KaspiImportParser } from '@finmap/import-parsers/kaspi-pdf-parser';
import { KaspiXlsxParser } from '@finmap/import-parsers/kaspi-xslx-parser';
import { KredoBankImportParser } from '@finmap/import-parsers/kredo-bank-import-parser';
import { MBankVATPdfParser } from '@finmap/import-parsers/mbank-vat-pdf-parser';
import { MercuryBankImportParser } from '@finmap/import-parsers/mercurybank-csv-parser';
import { OschadBankImportParser } from '@finmap/import-parsers/oschadbank-import-parser';
import { OTPHungaryImportParser } from '@finmap/import-parsers/otphungary-import-parser';
import { OTPUkraineXlsParser } from '@finmap/import-parsers/otpukraine-xls-parser';
import { PaypalImportParser } from '@finmap/import-parsers/paypal-import-parser';
import { PayseraImportParser } from '@finmap/import-parsers/paysera-import-parser';
import { PKOBankImportParser } from '@finmap/import-parsers/pko-bank-import-parser';
import { PlanfactXlsxImporter } from '@finmap/import-parsers/planfact-import-parser';
import { PrivatBankImportParser } from '@finmap/import-parsers/private-personal-pdf-parser';
import { ProCreditBankImportParser } from '@finmap/import-parsers/procredit-bank-import-parser';
import { PUMBPersonalImportParser } from '@finmap/import-parsers/pumb-personal-import-parser';
import { RaiffeisenImportParser } from '@finmap/import-parsers/raiffeisen-import-parser';
import { RBKKzBankImportParser } from '@finmap/import-parsers/rbk-kz-bank-import-parser';
import { RwSBankImportParser } from '@finmap/import-parsers/rws-bank-import-parser';
import { SantanderBankImportParser } from '@finmap/import-parsers/santander-csv-parser';
import { SebBankLatviaCsvParser } from '@finmap/import-parsers/sebbanklatvia-csv-parser';
import { SenseBankImportParser } from '@finmap/import-parsers/sense-bank-import-parser';
import { SilverbirdBankImportParser } from '@finmap/import-parsers/silverbird-bank-import-parser';
import { SportbankImportParser } from '@finmap/import-parsers/sportbank-import-parser';
import { TaskombankImportParser } from '@finmap/import-parsers/taskombank-import-parser';
import { ThreeSMoneyCsvParser } from '@finmap/import-parsers/threes-money-csv-parser';
import { UkrGazBankImportParser } from '@finmap/import-parsers/ukrgazbank-import-parser';
import { UkrsibBankImportParser } from '@finmap/import-parsers/ukrsibbank-import-parser';
import { UniversalBankImportParser } from '@finmap/import-parsers/universal-bank-import-parser';
import { UnlimintImportParser } from '@finmap/import-parsers/unlimint-import-parser';
import { UpworkImportParser } from '@finmap/import-parsers/upwork-import-parser';
import { VostokBankXlsParser } from '@finmap/import-parsers/vostok-bank-xls-parser';
import { WiseBusinessImportParser } from '@finmap/import-parsers/wise-business-import-parser';
import { KapitalbankParser } from '@finmap/import-parsers/kapitalbank-parser';
import { IpalYuliBankParser } from '@finmap/import-parsers/ipal-yuli-bank-parser';
import { UbuzParser } from '@finmap/import-parsers/ubuz-parser';
import { OrintFinansBankParser } from '@finmap/import-parsers/orient-finans-bank';
import { BglParibasParser } from '@finmap/import-parsers/bgl-paribas';
import { FreedomParser } from '@finmap/import-parsers/freedom';
import { KuveytParser } from '@finmap/import-parsers/kuveyt';
import { CominbankParser } from '@finmap/import-parsers/cominbank';
import { FakturowniaParser } from '@finmap/import-parsers/fakturownia';
import { DniproParser } from '@finmap/import-parsers/dnipro';
import { SkyParser } from '@finmap/import-parsers/sky';
import { VeloParser } from '@finmap/import-parsers/velo';
import { ErsteParser } from '@finmap/import-parsers/erste';
import { DemirParser } from '@finmap/import-parsers/demir';
import { BisbankParser } from '@finmap/import-parsers/bisbank';
import { MonoBusinessParser } from '@finmap/import-parsers/mono-business';
import { NovoBancoParser } from '@finmap/import-parsers/novo-banco';
import { UkreximbankParser } from '@finmap/import-parsers/ukreximbank';
import { PayoneerParser } from '@finmap/import-parsers/payoneer';
import { MtbBankParser } from '@finmap/import-parsers/mtb-bank';
import { TrusteeParser } from '@finmap/import-parsers/trustee';
import { BvrParser } from '@finmap/import-parsers/bvr';
import { MonobankJarParser } from '@finmap/import-parsers/monobank-jar';
import { MonobankParser } from '@finmap/import-parsers/monobank';
import { BankOfamericaParser } from '@finmap/import-parsers/bank-ofamerica';
import { MilleniumBankParser } from '@finmap/import-parsers/millenium-bank';
import { ChaseBankParser } from '@finmap/import-parsers/chase-bank';
import { PostFinanceParser } from '@finmap/import-parsers/post-finance';
import { RevolutParser } from '@finmap/import-parsers/revolut';
import { AltBankParser } from '@finmap/import-parsers/alt-bank';
import { IziBankParser } from '@finmap/import-parsers/izi-bank';
import { NovaPayParser } from '@finmap/import-parsers/nova-pay';
import { SqbParser } from '@finmap/import-parsers/sqb';
import { AsiaAllianceBankParser } from '@finmap/import-parsers/asia-alliance-bank';
import { XlsxParserV2 } from '@finmap/import-parsers/xlsx-parser-v2';

import { ImportType } from '../../store/import/types';

export const getParser = (type: ImportType, language: string) => {
  switch (type) {
    case ImportType.planfact:
      return new PlanfactXlsxImporter(language);
    case ImportType.finolog:
      return new FinlogXlsxParser(language);
    case ImportType.finmap1:
      return new Finmap1XlsxParser(language);
    case ImportType.raiffeisenbank:
      return new RaiffeisenImportParser();
    case ImportType['1c']:
      return new BAS1CxlsParser(language);
    case ImportType.alfabank:
      return new AlfaBankImportParser();
    case ImportType.fintablo:
      return new FintabloXlsxParser(language);
    case ImportType.kredoBank:
      return new KredoBankImportParser();
    case ImportType.ukrsibbank:
      return new UkrsibBankImportParser();
    case ImportType.kaspiBusiness:
      return new KaspiXlsxParser(language);
    case ImportType.santanderPoland:
      return new SantanderBankImportParser();
    case ImportType.mBankVATPoland:
      return new MBankVATPdfParser(language);
    case ImportType.otpHungary:
      return new OTPHungaryImportParser();
    case ImportType.otpBank:
      return new OTPUkraineXlsParser(language);
    case ImportType.sebBankLatvia:
      return new SebBankLatviaCsvParser(language);
    case ImportType['3sMoney']:
      return new ThreeSMoneyCsvParser(language);
    case ImportType.ingBank:
      return new INGBankPLNImportParser();
    case ImportType.forteBank:
      return new ForteBankPdfParser(language);
    case ImportType.jusanBank:
      return new JusanbankImportParser();
    case ImportType.proCreditBank:
      return new ProCreditBankImportParser();
    case ImportType.vostokBank:
      return new VostokBankXlsParser(language);
    case ImportType.mercuryBank:
      return new MercuryBankImportParser();
    case ImportType.centerCreditBank:
      return new CenterCreditBankImportParser();
    case ImportType.ecoCenterBank:
      return new EcoCenterBankPdfParser(language);
    case ImportType.upwork:
      return new UpworkImportParser();
    case ImportType.bankRBK:
      return new RBKKzBankImportParser();
    case ImportType.privatBank:
      return new PrivatBankImportParser();
    case ImportType.pumbPersonal:
      return new PUMBPersonalImportParser();
    case ImportType.kaspiBank:
      return new KaspiImportParser();
    case ImportType.ukrGazBank:
      return new UkrGazBankImportParser();
    case ImportType.universalBank:
      return new UniversalBankImportParser();
    case ImportType.pkoBank:
      return new PKOBankImportParser();
    case ImportType.taskombank:
      return new TaskombankImportParser();
    case ImportType.halykbank:
      return new HalykBankImportParser();
    case ImportType.rwsBank:
      return new RwSBankImportParser();
    case ImportType.bankPivdenny:
      return new BankPivdennyImportParser();
    case ImportType.bankPekao:
      return new BankPekaoImportParser();
    case ImportType.wiseBusiness:
      return new WiseBusinessImportParser();
    case ImportType.creditAgricole:
      return new CreditAgricoleImportParser();
    case ImportType.unlimint:
      return new UnlimintImportParser();
    case ImportType.paysera:
      return new PayseraImportParser();
    case ImportType.oschadbank:
      return new OschadBankImportParser();
    case ImportType.abank:
      return new AbankImportParser();
    case ImportType.paypal:
      return new PaypalImportParser();
    case ImportType.senseBank:
      return new SenseBankImportParser();
    case ImportType.sportbank:
      return new SportbankImportParser();
    case ImportType.bsiBank:
      return new BSIBankImportParser();
    case ImportType.silverbirdBank:
      return new SilverbirdBankImportParser();
    case ImportType.orintFinansBank:
      return new OrintFinansBankParser();
    case ImportType.bglParibas:
      return new BglParibasParser();
    case ImportType.freedom:
      return new FreedomParser();
    case ImportType.kuveyt:
      return new KuveytParser();
    case ImportType.cominbank:
      return new CominbankParser();
    case ImportType.fakturownia:
      return new FakturowniaParser();
    case ImportType.dnipro:
      return new DniproParser();
    case ImportType.sky:
      return new SkyParser();
    case ImportType.velo:
      return new VeloParser();
    case ImportType.erste:
      return new ErsteParser();
    case ImportType.demir:
      return new DemirParser();
    case ImportType.bisbank:
      return new BisbankParser();
    case ImportType.monoBusiness:
      return new MonoBusinessParser();
    case ImportType.novoBanco:
      return new NovoBancoParser();
    case ImportType.ukreximbank:
      return new UkreximbankParser();
    case ImportType.payoneer:
      return new PayoneerParser();
    case ImportType.mtbBank:
      return new MtbBankParser();
    case ImportType.trustee:
      return new TrusteeParser();
    case ImportType.bvr:
      return new BvrParser();
    case ImportType.monobankJar:
      return new MonobankJarParser();
    case ImportType.monobank:
      return new MonobankParser();
    case ImportType.bankOfamerica:
      return new BankOfamericaParser();
    case ImportType.milleniumBank:
      return new MilleniumBankParser();
    case ImportType.chaseBank:
      return new ChaseBankParser();
    case ImportType.postFinance:
      return new PostFinanceParser();
    case ImportType.revolut:
      return new RevolutParser();
    case ImportType.altBank:
      return new AltBankParser();
    case ImportType.iziBank:
      return new IziBankParser();
    case ImportType.novaPay:
      return new NovaPayParser();
    case ImportType.sqb:
      return new SqbParser();
    case ImportType.asiaAllianceBank:
      return new AsiaAllianceBankParser();
    case ImportType.kapitalbank:
      return new KapitalbankParser();
    case ImportType.ipalYuliBank:
      return new IpalYuliBankParser();
    case ImportType.NBUz:
      return new UbuzParser();
    default:
      return new XlsxParserV2(language);
  }
};
