import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class FreedomParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                in: 'Дата выписки',
              },
            ],
          ],
          proceedCaseConfig: {
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      in: '№',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Дата операции',
                    },
                  ],
                ],
                count: 1,
              },
              {
                from: [
                  [
                    0,
                    {
                      eq: '',
                    },
                  ],
                  [
                    1,
                    {
                      eq: '',
                    },
                  ],
                  [
                    3,
                    {
                      eq: '',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              debit: {
                column: ['Дебет', 8],
              },
              credit: {
                column: ['Кредит', 9],
              },
              date: {
                column: ['Дата операции', 1],
                dateFormat: 'DD.MM.YYYY',
              },
              comment: {
                column: ['Назначение платежа', 10],
                replace: [
                  { from: '\n', to: '' },
                  { from: '\r', to: '' },
                  { from: '\t', to: '' },
                ],
              },
              counterparty: {
                column: ['Наименование\nбанка\nотправителя\n/получателя', 3],
              },
            },
          },
        },
      },
    ],
  };
}
