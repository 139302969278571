import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import {
  selectAllCategoriesCount,
  selectAllPLCategoriesCount,
  selectFullLogConsumptionCategoriesIds,
  selectFullLogIncomeCategoriesIds,
} from '../categories/selectors';
import { createBaseQuery } from '../journal/selectors';
import { OperationType } from '../operations/types';
import {
  CREATE_USER_FILTER_PENDING,
  CREATE_USER_FILTER_SUCCESS,
  GET_USER_FILTERS_PENDING,
  GET_USER_FILTERS_SUCCESS,
  REMOVE_USER_FILTER_PENDING,
  REMOVE_USER_FILTER_SUCCESS,
  UPDATE_USER_CUSTOM_FILTER_PENDING,
  UPDATE_USER_CUSTOM_FILTER_SUCCESS,
  UPDATE_USER_FILTER_PENDING,
  UPDATE_USER_FILTER_SUCCESS,
} from './actions';
import api from './customFiltersApi';
import {
  CreateUserFilterPayload,
  CreateUserFilterRequest,
  RemoveUserFilterPayload,
  UpdateAllUserFilterPayload,
  UpdateUserFilterPayload,
} from './customFiltersApi.types';
import {
  getFilterNameById,
  selectCustomFilters,
  selectFilters,
} from './selectors';
import { PeriodsFilterTypes } from './types';

export function* getUserFilters() {
  try {
    const { data } = yield call(api.getCustomFilters);

    yield put({ type: GET_USER_FILTERS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* createUserFilter(
  action: TAction<CreateUserFilterPayload>,
): SagaIterator {
  try {
    const { selector, name } = action.payload;
    const { ordersQuery, filters, customFiltersQuery } = yield select(
      createBaseQuery(selector),
    );
    const customFilters = yield select(selectCustomFilters(selector));
    const plCategoriesCount = yield select(selectAllPLCategoriesCount);

    let categoriesCount = yield select(selectAllCategoriesCount);

    const incomeCategories = yield select(selectFullLogIncomeCategoriesIds);
    const consumptionCategories = yield select(
      selectFullLogConsumptionCategoriesIds,
    );

    if (
      selector === 'profitAndLoss' ||
      selector === 'tableProfitAndLoss' ||
      selector === 'logTableProfitAndLoss'
    ) {
      categoriesCount = plCategoriesCount;
    }

    const {
      tags,
      clients,
      accounts,
      projects,
      categoriesByIds,
      categoriesByType,
    } = customFilters;
    const { startDate, endDate } = filters;

    const types: OperationType[] = [...categoriesByType];

    const categoryV2Ids =
      categoriesByIds.length === categoriesCount ? undefined : categoriesByIds;

    if (
      categoryV2Ids?.length &&
      !types.includes(OperationType.income) &&
      incomeCategories?.length
    ) {
      const hasIncome = categoryV2Ids.some((el: string) =>
        incomeCategories.includes(el),
      );
      if (hasIncome) {
        types.push(OperationType.income);
      }
    }

    if (
      categoryV2Ids?.length &&
      !types.includes(OperationType.consumption) &&
      consumptionCategories?.length
    ) {
      const hasConsumption = categoryV2Ids.some((el: string) =>
        consumptionCategories.includes(el),
      );
      if (hasConsumption) {
        types.push(OperationType.consumption);
      }
    }

    const dateFilter: PeriodsFilterTypes = yield select(
      selectFilters('journal', 'futureFilter'),
    );

    const query =
      Object?.keys(dateFilter)
        .map((filter) =>
          // @ts-ignore
          dateFilter[filter] !== undefined
            ? // @ts-ignore
              `${filter}=${dateFilter[filter]}`
            : '',
        )
        .join('&') || '';

    const filter: CreateUserFilterRequest = {
      query: `offset=0&limit=100&${query}&${ordersQuery}&${customFiltersQuery}`,
      name,
      accountIds: accounts,
      approved: true,
      categoryV2Ids,
      counterpartyIds: clients,
      desc: true,
      startDate,
      endDate,
      field: 'date',
      limit: 100,
      offset: 0,
      projectIds: projects,
      tagIds: tags,
      types,
    };

    const { data } = yield call(api.createCustomFilter, filter);

    yield put({ type: CREATE_USER_FILTER_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* updateAllUserFilter(
  action: TAction<UpdateAllUserFilterPayload>,
): SagaIterator {
  try {
    const { selector, _id } = action.payload;
    const name = yield select(getFilterNameById(_id));
    const { ordersQuery, filters, customFiltersQuery } = yield select(
      createBaseQuery(selector),
    );

    const customFilters = yield select(selectCustomFilters(selector));
    const plCategoriesCount = yield select(selectAllPLCategoriesCount);

    let categoriesCount = yield select(selectAllCategoriesCount);

    const incomeCategories = yield select(selectFullLogIncomeCategoriesIds);
    const consumptionCategories = yield select(
      selectFullLogConsumptionCategoriesIds,
    );

    if (
      selector === 'profitAndLoss' ||
      selector === 'tableProfitAndLoss' ||
      selector === 'logTableProfitAndLoss'
    ) {
      categoriesCount = plCategoriesCount;
    }

    const {
      tags,
      clients,
      accounts,
      projects,
      categoriesByIds,
      categoriesByType,
    } = customFilters;
    const { startDate, endDate } = filters;

    const types: OperationType[] = [...categoriesByType];

    const categoryV2Ids =
      categoriesByIds.length === categoriesCount ? undefined : categoriesByIds;

    if (
      categoryV2Ids?.length &&
      !types.includes(OperationType.income) &&
      incomeCategories?.length
    ) {
      const hasIncome = categoryV2Ids.some((el: string) =>
        incomeCategories.includes(el),
      );
      if (hasIncome) {
        types.push(OperationType.income);
      }
    }

    if (
      categoryV2Ids?.length &&
      !types.includes(OperationType.consumption) &&
      consumptionCategories?.length
    ) {
      const hasConsumption = categoryV2Ids.some((el: string) =>
        consumptionCategories.includes(el),
      );
      if (hasConsumption) {
        types.push(OperationType.consumption);
      }
    }

    const dateFilter: PeriodsFilterTypes = yield select(
      selectFilters('journal', 'futureFilter'),
    );

    const query =
      Object?.keys(dateFilter)
        .map((filter) =>
          // @ts-ignore
          dateFilter[filter] !== undefined
            ? // @ts-ignore
              `${filter}=${dateFilter[filter]}`
            : '',
        )
        .join('&') || '';

    const filter: CreateUserFilterRequest & { _id: string } = {
      _id,
      query: `offset=0&limit=100&${query}&${ordersQuery}&${customFiltersQuery}`,
      name,
      accountIds: accounts,
      approved: true,
      categoryV2Ids,
      counterpartyIds: clients,
      desc: true,
      startDate,
      endDate,
      field: 'date',
      limit: 100,
      offset: 0,
      projectIds: projects,
      tagIds: tags,
      types,
    };

    const { data } = yield call(api.updateCustomFilterById, filter);

    yield put({ type: UPDATE_USER_FILTER_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* removeUserFilter(action: TAction<RemoveUserFilterPayload>) {
  try {
    const { data } = yield call(api.removeCustomFilter, action.payload.id);

    yield put({ type: REMOVE_USER_FILTER_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* updateUserFilter(action: TAction<UpdateUserFilterPayload>) {
  const { filter } = action.payload;

  try {
    const { data } = yield call(api.updateCustomFilter, filter);

    yield put({ type: UPDATE_USER_CUSTOM_FILTER_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export default function common() {
  return all([
    takeLatest(GET_USER_FILTERS_PENDING, getUserFilters),
    takeLatest(CREATE_USER_FILTER_PENDING, createUserFilter),
    takeLatest(UPDATE_USER_FILTER_PENDING, updateAllUserFilter),
    takeLatest(REMOVE_USER_FILTER_PENDING, removeUserFilter),
    takeLatest(UPDATE_USER_CUSTOM_FILTER_PENDING, updateUserFilter),
  ]);
}
