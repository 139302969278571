import './styles.scss';

import { CalculatorKeyProps } from './types';

export function CalculatorKey({
  onClick,
  className,
  keyValue,
  disabled,
}: CalculatorKeyProps) {
  return (
    <button
      className={`calculator-key ${className}`}
      type="button"
      onClick={onClick}
      disabled={disabled}
      data-testid="calculator-key"
    >
      {keyValue}
    </button>
  );
}
