import { round } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getShowCoins } from '../store/common/selectors';
import { convertExpToString } from '../utils/stringUtils';

function useFormatBalance() {
  const showCoins = useSelector(getShowCoins);

  const getBalance = useCallback(
    (amount: number | string, digits = 2) => {
      if (amount === 0 || amount === '0') {
        return 0;
      }

      if (amount) {
        if (showCoins) {
          return round(Number(amount), digits)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        } else {
          return Math.trunc(Number(amount))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
      }

      return null;
    },
    [showCoins],
  );

  const formatDigitsToLowCase = useCallback(
    (val: number, symbol: string, title = false, isCrypto?: boolean) => {
      if (isCrypto) {
        return <span>{`${symbol} ${convertExpToString(val)}`}</span>;
      }

      let first = '0';
      let fraction = '0';

      const fractionTitleStyles = {
        fontSize: 16,
      };

      const fractionStyles = {
        fontSize: 10,
      };

      const balance = getBalance(val);

      if (balance) {
        [first, fraction] = balance.split('.');
      }

      return (
        <>
          {`${symbol} ${first}`}
          {!!fraction && showCoins && (
            <span style={title ? fractionTitleStyles : fractionStyles}>
              {`.${fraction}`}
            </span>
          )}
        </>
      );
    },
    [showCoins, getBalance],
  );

  return { getBalance, formatDigitsToLowCase };
}

export default useFormatBalance;
