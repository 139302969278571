export type ImportState = {
  pdfImportBuffer: any | null;
};

export enum ImportType {
  excel = 'excel',
  planfact = 'planfact',
  finolog = 'finolog',
  finmap1 = 'finmap1',
  raiffeisenbank = 'raiffeisenbank',
  '1c' = '1c',
  alfabank = 'alfabank',
  kredoBank = 'kredoBank',
  fintablo = 'fintablo',
  ukrsibbank = 'ukrsibbank',
  kaspiBusiness = 'kaspiBusiness',
  kaspiPrivate = 'kaspiPrivate',
  otpHungary = 'otpHungary',
  otpBank = 'otpBank',
  santanderPoland = 'santanderPoland',
  mBankVATPoland = 'mBankVATPoland',
  sebBankLatvia = 'sebBankLatvia',
  '3sMoney' = '3sMoney',
  oschadbank = 'oschadbank',
  halykbank = 'halykbank',
  ingBank = 'ingBank',
  forteBank = 'forteBank',
  jusanBank = 'jusanBank',
  proCreditBank = 'proCreditBank',
  vostokBank = 'vostokBank',
  mercuryBank = 'mercuryBank',
  centerCreditBank = 'centerCreditBank',
  ecoCenterBank = 'ecoCenterBank',
  upwork = 'upwork',
  bankRBK = 'bankRBK',
  privatBank = 'privatBank',
  pumbPersonal = 'pumbPersonal',
  kaspiBank = 'kaspiBank',
  ukrGazBank = 'ukrGazBank',
  universalBank = 'universalBank',
  taskombank = 'taskombank',
  rwsBank = 'rwsBank',
  bankPivdenny = 'bankPivdenny',
  bankPekao = 'bankPekao',
  pkoBank = 'pkoBank',
  wiseBusiness = 'wiseBusiness',
  creditAgricole = 'creditAgricole',
  unlimint = 'unlimint',
  paysera = 'paysera',
  abank = 'abank',
  paypal = 'paypal',
  senseBank = 'senseBank',
  sportbank = 'sportbank',
  bsiBank = 'bsiBank',
  silverbirdBank = 'silverbirdBank',
  kapitalbank = 'kapitalbank',
  ipalYuliBank = 'ipalYuliBank',
  NBUz = 'NBUz',
  orintFinansBank = 'orintFinansBank',
  bglParibas = 'bglParibas',
  freedom = 'freedom',
  kuveyt = 'kuveyt',
  cominbank = 'cominbank',
  fakturownia = 'fakturownia',
  dnipro = 'dnipro',
  sky = 'sky',
  velo = 'velo',
  erste = 'erste',
  demir = 'demir',
  bisbank = 'bisbank',
  monoBusiness = 'monoBusiness',
  novoBanco = 'novoBanco',
  ukreximbank = 'ukreximbank',
  payoneer = 'payoneer',
  mtbBank = 'mtbBank',
  trustee = 'trustee',
  bvr = 'bvr',
  monobankJar = 'monobankJar',
  monobank = 'monobank',
  bankOfamerica = 'bankOfamerica',
  milleniumBank = 'milleniumBank',
  chaseBank = 'chaseBank',
  postFinance = 'postFinance',
  revolut = 'revolut',
  altBank = 'altBank',
  iziBank = 'iziBank',
  novaPay = 'novaPay',
  sqb = 'sqb',
  asiaAllianceBank = 'asiaAllianceBank',
}
