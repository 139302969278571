import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class MonobankParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                eq: 'АТ "УНІВЕРСАЛ БАНК"',
              },
            ],
            [
              32,
              {
                in: 'USD',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Сума в',
                    },
                    nexWord: {
                      eq: 'Сума в',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: '(USD)',
                    },
                    prevWord: {
                      eq: 'операції',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Сума в',
                      },
                      nexWord: {
                        eq: 'Сума в',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'Операційний директор',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'DD.MM.YYYY',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата i час\nоперації', 0],
                replace: [
                  {
                    from: '\n',
                    to: ' ',
                  },
                ],
              },
              comment: {
                column: ['Деталі операції', 1],
                add: [
                  ', ',
                  'Сума в валюті операції: ',
                  {
                    column: ['Сума в\nвалюті\nоперації', 4],
                  },
                  ', ',
                  'Валюта: ',
                  {
                    column: ['Валюта', 5],
                  },
                  ', ',
                  'Курс: ',
                  {
                    column: ['Курс', 6],
                  },
                ],
              },
              sum: {
                column: ['Сума в\nвалюті\nкартки', 3],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Сума у',
                    },
                    nexWord: {
                      eq: 'валюті',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'зарахувань)',
                    },
                    prevWord: {
                      eq: '(для',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Сума у',
                      },
                      nexWord: {
                        eq: 'валюті',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'Операційний директор',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'DD.MM.YYYY',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата та час\nоперації', 0],
              },
              comment: {
                column: ['Деталі операції', 1],
              },
              sum: {
                column: ['Сума\nоперації', 3],
                replace: [
                  {
                    from: ' ',
                    to: '',
                  },
                ],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                in: 'Клієнт:',
              },
            ],
          ],
          proceedCaseConfig: {
            withoutEmpty: true,
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Дата i час операції',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Деталі операції',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              sum: {
                column: ['Сума в валюті картки (UAH)', 3],
              },
              dateAndTime: {
                column: ['Дата i час операції', 0],
              },
              comment: {
                column: ['Деталі операції', 1],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                eq: 'Дата i час операції',
              },
            ],
          ],
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата та час операції', 0],
              },
              comment: {
                column: ['Деталі операції', 1],
              },
              sum: {
                column: ['Сума в валюті картки (UAH)', 3],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                eq: 'Date',
              },
            ],
          ],
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Date', 0],
                dateFormat: 'DD MMM, YYYY',
              },
              comment: {
                column: ['Description', 1],
              },
              sum: {
                column: ['Amount', 2],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                eq: 'Дата \r\nоперації',
              },
            ],
            [
              1,
              {
                eq: 'Час \r\nоперації',
              },
            ],
          ],
          proceedCaseConfig: {
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Дата \r\nоперації',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Час \r\nоперації',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              date: {
                column: ['Дата \nоперації', 0],
              },
              time: {
                column: ['Час \nоперації', 1],
              },
              comment: {
                column: ['Деталі операції', 3],
              },
              sum: {
                column: ['Сума в валюті \nрахунку', 7],
              },
              counterparty: {
                column: ['Контрагент', 4],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                eq: 'Дата \nоперації',
              },
            ],
            [
              1,
              {
                eq: 'Час \nоперації',
              },
            ],
          ],
          proceedCaseConfig: {
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Дата \nоперації',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Час \nоперації',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              date: {
                column: ['Дата \nоперації', 0],
              },
              time: {
                column: ['Час \nоперації', 1],
              },
              comment: {
                column: ['Деталі операції', 3],
              },
              sum: {
                column: ['Сума в валюті \nрахунку', 7],
              },
              counterparty: {
                column: ['Контрагент', 4],
              },
            },
          },
        },
      },
    ],
  };
}
