import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import Loader from '../../../components/Loader/LottieLoader';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import accountsApi from '../../../services/accounts';
import posterApi from '../../../services/bankIntegrations/poster';
import Storages, { StorageKey } from '../../../services/Storages';
import { selectCurrentCompany } from '../../../store/company/selectors';
import actions from '../../../store/integrations/actions';
import nordigenActions from '../../../store/integrations/nordigenActions';
import { CreateIntegrationPayload } from '../../../store/integrations/sagas.types';
import { IntegrationTypeId } from '../../../store/integrations/types';
import { getTimeOffset } from '../../../utils/dateToUTC';
import AccountSelector from '../../Operations/Components/AccountSelector';
import ErrorDialog from '../ErrorDialog';
import { useStyles } from './styles';
import { PosterAccount } from './types';
import useFormatBalance from '../../../hooks/useFormatBalance';

function PosterAccountsDialog() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const facebookContext = useContext(FacebookPixelContext);
  const { getBalance } = useFormatBalance();

  const [accounts, setAccounts] = useState<PosterAccount[] | null>(null);
  const [currentAccount, setCurrentAccount] = useState<PosterAccount | null>(
    null,
  );
  const [showDialog, setShowDialog] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [integrationLoading, setIntegrationLoading] = useState(false);

  const company = useSelector(selectCurrentCompany);

  const handleCloseErrorDialog = useCallback(() => {
    Storages.remove(StorageKey.poster);
    setAccounts(null);
    setShowErrorDialog(false);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);
    Storages.remove(StorageKey.poster);
  }, []);

  const handleChangeAccount = useCallback((val: any) => {
    setCurrentAccount(val);
  }, []);

  const handleIntegrationStart = useCallback(async () => {
    const posterIntegration = Storages.get(StorageKey.poster);

    setIntegrationLoading(true);

    if (posterIntegration && currentAccount) {
      const {
        integrationCredentialsId,
        newAccountName,
        accountId: storedAccountId,
        startDate,
        bankName,
      } = posterIntegration;

      let accountId = storedAccountId;

      if (!accountId) {
        const {
          data: { newAccount },
        } = await accountsApi.createAccount(
          currentAccount.currencyId,
          newAccountName,
          currentAccount.balance,
          IntegrationTypeId.Poster,
        );

        accountId = newAccount._id;
      }

      dispatch(actions.setPosterIntegrationId(integrationCredentialsId));

      const payload: CreateIntegrationPayload = {
        typeId: IntegrationTypeId.Poster,
        accountId,
        startDate: new Date(startDate).getTime() + getTimeOffset(),
        bankName,
        facebookContext,
      };

      await posterApi.chooseAccount(
        currentAccount.accountId,
        integrationCredentialsId,
      );

      dispatch(nordigenActions.createNordigenIntegration(payload));

      setIntegrationLoading(false);
      handleCloseDialog();
    }
  }, [dispatch, currentAccount, handleCloseDialog, facebookContext]);

  useEffect(() => {
    const posterIntegration = Storages.get(StorageKey.poster);

    if (!posterIntegration) return;

    const getPosterAccounts = async () => {
      try {
        if (posterIntegration.accounts)
          return setAccounts(posterIntegration.accounts);

        if (posterIntegration.setTokenStarted) return null;

        Storages.put(StorageKey.poster, {
          ...posterIntegration,
          setTokenStarted: true,
        });

        setLoading(true);

        const {
          data: { integrationCredentialsId, accounts: accountsFromApi },
        } = await posterApi.setTokens(
          posterIntegration.account,
          posterIntegration.code,
        );

        setLoading(false);

        if (!integrationCredentialsId) {
          Storages.remove(StorageKey.poster);
        } else {
          const formatAccounts = accountsFromApi.map((el: PosterAccount) => ({
            ...el,
            name: `${el.name || ''} ${getBalance(el.balance)} ${el.currencyId}`,
          }));

          Storages.put(StorageKey.poster, {
            ...posterIntegration,
            accounts: formatAccounts,
            integrationCredentialsId,
          });

          setAccounts(formatAccounts);
          setShowDialog(true);
        }
      } catch (e) {
        setLoading(false);
        setShowErrorDialog(true);
      }
      return null;
    };

    if (posterIntegration?.code && !showErrorDialog) getPosterAccounts();
  }, [company, showErrorDialog, getBalance]);

  if (showErrorDialog) return <ErrorDialog onClose={handleCloseErrorDialog} />;

  if (loading) {
    return (
      <div className={classes.overlay}>
        <Loader size="medium" />
      </div>
    );
  }

  const posterIntegration = Storages.get(StorageKey.poster);

  if (!posterIntegration) return null;

  return (
    <Dialog
      isOpened={showDialog}
      onClose={handleCloseDialog}
      title={t('operationDialogs.account.placeholder')}
    >
      <AccountSelector
        // @ts-ignore
        value={currentAccount}
        // @ts-ignore
        accounts={accounts}
        onChange={handleChangeAccount}
        disablePortal={false}
      />
      <CustomButton
        fullWidth
        action={handleIntegrationStart}
        loading={integrationLoading}
        title={t('bank.connectAccount')}
      />
    </Dialog>
  );
}

export default React.memo(PosterAccountsDialog);
