import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AutocompleteSelector from '../../../../components/AutocompleteSelector';
import CustomButton from '../../../../components/Button';
import Dialog from '../../../../components/Dialog/Dialog';
import useFormatBalance from '../../../../hooks/useFormatBalance';
import { PumbAccount } from '../../../../store/integrations/types';
import { minOperationDate } from '../../../../utils/dateFormat';
import CalendarComponent from '../../../Operations/Components/CalendarComponent/CalendarComponent';
import { useStyles } from './styles';
import { Props } from './types';

function PumbAccountsDialog(props: Props) {
  const { error, onClose, loading, pumbAccounts, onSetAccount } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { getBalance } = useFormatBalance();

  const [currentCard, setCurrentCard] = useState<PumbAccount | null>(null);
  const [errors, setErrors] = useState({
    date: false,
    card: false,
  });
  const [selectedDate, setSelectedDate] = useState<number | null>(null);
  const [errorText, setErrorText] = useState('');

  const formatAccounts = useMemo(
    () =>
      pumbAccounts.map((acc: PumbAccount) => ({
        id: acc.accountId,
        balance: acc.balance,
        currency: acc.currency,
        name: `${acc.name} ${getBalance(acc.balance)} ${acc.currency}`,
      })),
    [pumbAccounts, getBalance],
  );

  const handleSetAccount = useCallback(() => {
    if (!selectedDate) {
      setErrors((values) => ({ ...values, date: true }));

      return;
    }

    if (!currentCard) {
      setErrors((values) => ({ ...values, card: true }));

      return;
    }

    onSetAccount({
      account: currentCard,
      fromDate: new Date(selectedDate),
      toDate: new Date(),
    });
  }, [onSetAccount, selectedDate, currentCard]);

  const handleSelectCard = useCallback((value: any) => {
    setCurrentCard(value);
  }, []);

  const handleChangeDate = useCallback((value: any) => {
    setSelectedDate(value);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      setErrors((values) => ({ ...values, date: false }));
    }

    if (currentCard) {
      setErrors((values) => ({ ...values, card: false }));
    }
  }, [selectedDate, currentCard]);

  useEffect(() => {
    if (error) {
      setErrorText(t('bank.loadAccountsError.title'));
    }
  }, [t, error]);

  return (
    <Dialog onClose={onClose} isOpened title={t('integrations.title')}>
      <div className={classes.flexColumn}>
        <CalendarComponent
          onChange={handleChangeDate}
          placeholder={t('integrations.dateDescription')}
          // @ts-ignore
          value={selectedDate}
          fullWidth
          maxDate={new Date()}
          minDate={minOperationDate()}
          error={errors.date}
          fromIntegration
        />
        <AutocompleteSelector
          rootClassName={classes.selector}
          label={t('integrations.yourCards')}
          onChange={handleSelectCard}
          data={formatAccounts}
          // @ts-ignore
          value={currentCard}
          error={errors.card}
        />
        <CustomButton
          title={t('integrations.setCard')}
          action={handleSetAccount}
          loading={loading}
          fullWidth
          errorText={errorText}
        />
      </div>
    </Dialog>
  );
}

export default React.memo(PumbAccountsDialog);
