import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class BvrParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            separatorsDistanceModifier: 0.4,
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Дата',
                    },
                    nexWord: {
                      eq: 'Сума',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'бонусів',
                    },
                    prevWord: {
                      eq: 'операції',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Дата',
                      },
                      nexWord: {
                        eq: 'Сума',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'Загальна',
                      },
                      nexWord: {
                        eq: 'сума',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'DD.MM.YYYY',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Дата\nта час\nоперації', 0],
                split: {
                  by: '\n',
                  get: 0,
                },
              },
              comment: {
                column: ['Деталі\nоперації', 1],
              },
              sum: {
                column: ['Фактична сума\nоперації(UAH)', 4],
                replace: [
                  {
                    from: ',',
                    to: '.',
                  },
                ],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Виписка по рахунку',
              },
            ],
          ],
          proceedCaseConfig: {
            withoutEmpty: true,
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Дата\r\nта час\r\nоперації',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Деталі\r\nоперації',
                    },
                  ],
                ],
                count: 1,
              },
              {
                from: [
                  [
                    0,
                    {
                      eq: 'Загальна сума видаткових операцій за весь період:',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              sum: {
                column: ['Фактична сума операції(UAH)', 4],
                replace: [
                  {
                    from: ',',
                    to: '.',
                  },
                ],
              },
              comment: {
                column: ['Деталі\r\nоперації', 1],
              },
              dateAndTime: {
                column: ['Дата\r\nта час\r\nоперації', 0],
                add: [':00'],
              },
            },
          },
        },
      },
    ],
  };
}
