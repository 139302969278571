import './styles.scss';
import cn from 'classnames';
import map from 'lodash.map';
import { useEffect, useRef } from 'react';

import { OperationType } from '../../../store/operations/types';
import { CalculatorDisplay } from '../CalculatorDisplay';
import { CalculatorKey } from '../CalculatorKey';
import { useCalculator } from '../hooks';
import { EInputTypes } from '../types';
import { calculatorOperations, digitKeys } from '../utils';
import { CalculatorProps } from './types';

export function Calculator({ operationType }: CalculatorProps) {
  const { state, handleClick } = useCalculator();

  const calculatorRef = useRef<HTMLDivElement | null>(null);

  let funcKeyBackground = '';

  if (operationType === OperationType.income) {
    funcKeyBackground = 'operator-income-keys';
  } else if (operationType === OperationType.consumption) {
    funcKeyBackground = 'operator-consumption-keys';
  }

  useEffect(() => {
    const element = calculatorRef.current;

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, []);

  return (
    <div className="calculator-wrapper" ref={calculatorRef}>
      <div className="calculator" data-testid="react-mac-calculator">
        <CalculatorDisplay value={state.displayValue} />
        <div className="calculator-keypad">
          <div className="input-keys">
            <div className="function-keys">
              <CalculatorKey
                className="key-clear"
                onClick={() =>
                  handleClick(
                    state.displayValue !== '0'
                      ? EInputTypes.clearDisplay
                      : EInputTypes.clearAll,
                  )
                }
                keyValue={state.displayValue !== '0' ? 'C' : 'AC'}
              />
              <CalculatorKey
                className="key-sign"
                onClick={() => handleClick(EInputTypes.toggleSign)}
                keyValue="±"
              />
              <CalculatorKey
                className="key-percent"
                onClick={() => handleClick(EInputTypes.inputPercent)}
                keyValue="%"
              />
            </div>
            <div className="digit-keys">
              {digitKeys.map((digit) => (
                <CalculatorKey
                  key={`key-${digit}`}
                  className={`key-${digit}`}
                  onClick={() => handleClick(EInputTypes.inputDigit, digit)}
                  keyValue={digit}
                />
              ))}
              <CalculatorKey
                className="key-dot"
                onClick={() => handleClick(EInputTypes.inputDot)}
                keyValue="●"
                disabled={state.displayValue.includes('.')}
              />
            </div>
          </div>
          <div className={cn('operator-keys', funcKeyBackground)}>
            {map(calculatorOperations, (value, key) =>
              value.show ? (
                <CalculatorKey
                  key={`key-${value.name}`}
                  className={`key-${value.name}`}
                  onClick={() => handleClick(EInputTypes.performOperation, key)}
                  keyValue={value.symbol}
                />
              ) : null,
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
