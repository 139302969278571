import { convertToCrypto, convertToSum } from './currency';

export const iValidWithDeviation = (expected, actual, omission = 0.011) =>
  Math.abs(expected - actual) <= omission;

export const iValidPercentWithDeviation = (actual) =>
  Math.abs(100 - actual) <= 0.1;

export const preperePartials = (prepareItem): any[] => {
  const result = [];
  const emptyFields = [null, undefined];
  if (!prepareItem.hasPartial) return result;
  if (prepareItem?.projects?.length > 1) {
    for (const project of prepareItem.projectObjects) {
      if(!project._id) {
        console.log(`project without _id in operation ${prepareItem.operationId}`);
        continue;
      }
      if(emptyFields.includes(project.sum) || emptyFields.includes(project.companyCurrencySum)) {
        console.log(`project without sum or companyCurrencySum in operation ${prepareItem.operationId}`);
        continue;
      }
      result.push({
        ...prepareItem,
        _id: getReindexerOperationProjectId(prepareItem.operationId, project._id),
        isPartial: true,
        hasPartial: false,
        comment: undefined,
        searchString: undefined,
        projectObjects: [project],
        projects: project.label,
        projectIds: [project._id.toString() === '' ? 'empty' : project._id.toString()],

        sum: project.crypto
          ? convertToCrypto(project.sum)
          : convertToSum(project.sum),
        companyCurrencySum: project.crypto
          ? convertToCrypto(project.companyCurrencySum || project.sum)
          : convertToSum(project.companyCurrencySum || project.sum),
        sumDouble: project.sum,
        sumInStrig: project.sum?.toString() || '0',
        companyCurrencySumDouble: project.companyCurrencySum || project.sum,
      });
    }
  }
  return result;
}

export const getReindexerOperationProjectId = (operationId, projectId) => {
  return `${operationId}_project_${projectId}`;
}
