import { CustomPeriodEnum } from '../../foundation/Operations/HOC/types';
import { categoryActionTypes } from '../categories/actions';
import { types } from '../clients/actions';
import { UrlPrefix } from '../clients/types';
import { RESET_RATES } from '../currency/actions';
import { GetExchangeRateFromToPayload } from '../currency/types';
import { CREATE_OPERATION_PROJECTS_PENDING } from '../projects/actions';
import { CREATE_OPERATION_TAGS_PENDING } from '../tags/actions';
import {
  CreateOperationPayload,
  DeleteFuturePredictionPayload,
  GetOperationsItemsPayload,
  MergeFuturePredictionsPayload,
  MergeOperationsToTransferPayload,
  OperationToTransferPayload,
  PayOffFuturePayload,
  RemoveOperationsPayload,
  UpdateGroupAccountPayload,
  UpdateGroupApprovePayload,
  UpdateGroupCategoryPayload,
  UpdateGroupClientPayload,
  UpdateGroupCommentPayload,
  UpdateGroupProjectPayload,
  UpdateGroupTagPayload,
  UpdateOneOperationPayload,
  UpdateOperationPayload,
} from './sagas.types';
import {
  Attachment,
  OperationType,
  PeriodDateOfPaymentPayload,
  SetAccountAction,
  SetAccountPayload,
  SetAmountAction,
  SetAmountPayload,
  SetCommentsAction,
  SetCommentsPayload,
  SetCreateAndCopyStateAction,
  SetCreateAndCopyStatePayload,
  SetCurrencyAmountAction,
  SetCurrencyAmountPayload,
  SetCurrencyValueAction,
  SetCurrencyValuePayload,
  SetDateOfPaymentWasChangedAction,
  SetDateOfPaymentWasChangedPayload,
  SetEditOperationIdAction,
  SetEditOperationIdPayload,
  SetEndTimeStampAction,
  SetEndTimeStampPayload,
  SetExchangeRateAction,
  SetExchangeRatePayload,
  SetIncomeDateAction,
  SetIncomeDatePayload,
  SetIsCopyAction,
  SetIsCopyPayload,
  SetOperationPropsPayload,
  SetRepeatsAction,
  SetRepeatsPayload,
  SetRevertedExchangeRateAction,
  SetRevertExchangeRatePayload,
  SetRevertStateAction,
  SetSalaryPeriodIdAction,
  SetSalaryPeriodIdPayload,
  SetScheduledPayload,
  SetSelectedTagsAction,
  SetSelectedTagsPayload,
  SetSplitPaymentProjectsAction,
  SetSplitPaymentProjectsPayload,
  SetStartTimeStampAction,
  SetStartTimeStampPayload,
  SetTaxPeriodIdAction,
  SetTaxPeriodIdPayload,
  SetToAccountAction,
  SetToAccountPayload,
  UpdateOneOperationAction,
} from './types';

export const REMOVE_OPERATION_PENDING = 'REMOVE_OPERATION_PENDING';

export const UPDATE_ONE_OPERATION_PENDING = 'UPDATE_ONE_OPERATION_PENDING';
export const UPDATE_ONE_OPERATION_SUCCESS = 'UPDATE_ONE_OPERATION_SUCCESS';

export const UPDATE_OPERATION_PENDING = 'UPDATE_OPERATION_PENDING';

export const CREATE_OPERATION_PENDING = 'CREATE_OPERATION_PENDING';

export const CREATE_TRANSFER_PENDING = 'CREATE_TRANSFER_PENDING';
export const CREATE_TRANSFER_SUCCESS = 'CREATE_TRANSFER_SUCCESS';

export const UPDATE_GROUP_CATEGORY = 'UPDATE_GROUP_CATEGORY';
export const UPDATE_GROUP_PROJECT = 'UPDATE_GROUP_PROJECT';
export const UPDATE_GROUP_COMMENT = 'UPDATE_GROUP_COMMENT';
export const UPDATE_GROUP_TAG = 'UPDATE_GROUP_TAG';
export const UPDATE_GROUP_CLIENT = 'UPDATE_GROUP_CLIENT';
export const UPDATE_GROUP_APPROVE = 'UPDATE_GROUP_APPROVE';
export const UPDATE_GROUP_ACCOUNT = 'UPDATE_GROUP_ACCOUNT';

export const GET_DATA_FOR_CREATE_DEBIT_OPERATION =
  'GET_DATA_FOR_CREATE_DEBIT_OPERATION';

export const UPDATE_BY_LOCATION = 'UPDATE_BY_LOCATION';

export const MERGE_OPERATIONS_TO_TRANSFER = 'MERGE_OPERATIONS_TO_TRANSFER';
export const PAY_OFF_FUTURE = 'PAY_OFF_FUTURE';
export const MERGE_PREDICTIONS_FUTURE_OPERATIONS =
  'MERGE_PREDICTIONS_FUTURE_OPERATIONS';
export const DELETE_PREDICTION_FUTURE_OPERATIONS =
  'DELETE_PREDICTION_FUTURE_OPERATIONS';

export const OPERATION_TO_TRANSFER = 'OPERATION_TO_TRANSFER';

// deprecated
export const SET_IS_COPY_COMPONENT = 'SET_IS_COPY_COMPONENT';
// deprecated
export const SET_CREATE_AND_COPY_STATE = 'SET_CREATE_AND_COPY_STATE';

export enum ActionTypes {
  'SET_IS_COPY_COMPONENT' = 'SET_IS_COPY_COMPONENT',
  'SET_CREATE_AND_COPY_STATE' = 'SET_CREATE_AND_COPY_STATE',
  SET_SCHEDULED = 'SET_SCHEDULED',
  UPDATE_GROUP_OPERATION_PENDING = 'UPDATE_GROUP_OPERATION_PENDING',
  UPDATE_TYPE = 'UPDATE_TYPE',
  GET_CONTEXT_OPERATIONS_ITEMS = 'GET_CONTEXT_OPERATIONS_ITEMS',
  GET_SETTINGS_CATEGORIES = 'GET_SETTINGS_CATEGORIES',
  SET_AMOUNT = 'SET_AMOUNT',
  SET_ACCOUNT = 'SET_ACCOUNT',
  SET_EXCHANGE_RATE = 'SET_EXCHANGE_RATE',
  SET_TO_ACCOUNT = 'SET_TO_ACCOUNT',
  SET_CURRENCY_AMOUNT = 'SET_CURRENCY_AMOUNT',
  SET_EDIT_OPERATION_ID = 'SET_EDIT_OPERATION_ID',
  SET_INCOME_DATE = 'SET_INCOME_DATE',
  SET_DATE_OF_PAYMENT = 'SET_DATE_OF_PAYMENT',
  SET_REPEATS = 'SET_REPEATS',
  SET_SPLIT_PAYMENT_PROJECTS = 'SET_SPLIT_PAYMENT_PROJECTS',
  SET_SELECTED_TAGS = 'SET_SELECTED_TAGS',
  SET_SALARY_PERIOD_ID = 'SET_SALARY_PERIOD_ID',
  SET_TAX_PERIOD_ID = 'SET_TAX_PERIOD_ID',
  SET_COMMENTS = 'SET_COMMENTS',
  SET_START_TIME_STAMP = 'SET_START_TIME_STAMP',
  SET_END_TIME_STAMP = 'SET_END_TIME_STAMP',
  SET_DATE_OF_PAYMENT_WAS_CHANGED = 'SET_DATE_OF_PAYMENT_WAS_CHANGED',
  SET_CURRENCY_VALUE = 'SET_CURRENCY_VALUE',
  RESET_PROPS = 'RESET_PROPS',
  SET_OPERATION_PROPS = 'SET_OPERATION_PROPS',
  SET_REVERT_STATE = 'SET_REVERT_STATE',
  GET_EXCHANGE_RATE_FROM_TO_PENDING = 'GET_EXCHANGE_RATE_FROM_TO_PENDING',
  GET_EXCHANGE_RATE_FROM_TO_SUCCESS = 'GET_EXCHANGE_RATE_FROM_TO_SUCCESS',
  GET_EXCHANGE_RATE_TO_FROM_PENDING = 'GET_EXCHANGE_RATE_TO_FROM_PENDING',
  GET_EXCHANGE_RATE_TO_FROM_SUCCESS = 'GET_EXCHANGE_RATE_TO_FROM_SUCCESS',
  SET_ATTACHMENT = 'SET_ATTACHMENT',
  SET_ATTACHMENTS = 'SET_ATTACHMENTS',
  SET_REVERTED_EXCHANGE_RATE = 'SET_REVERTED_EXCHANGE_RATE',
  SET_OPERATION_TYPE = 'SET_OPERATION_TYPE',
  SET_DATE_OF_PAYMENT_PERIOD_ID = 'SET_DATE_OF_PAYMENT_PERIOD_ID',
  SET_PERIOD_DATE_OF_PAYMENT = 'SET_PERIOD_DATE_OF_PAYMENT',
}

const updateGroupOperations = (ids: string[], payload: any, location: any) => ({
  type: ActionTypes.UPDATE_GROUP_OPERATION_PENDING,
  payload: { ids, payload, location },
});

const setPeriodDateOfPayment = (data: PeriodDateOfPaymentPayload) => ({
  type: ActionTypes.SET_PERIOD_DATE_OF_PAYMENT,
  payload: data,
});

const resetOperationProps = () => ({
  type: ActionTypes.RESET_PROPS,
});

const setOperationType = (value: OperationType) => ({
  type: ActionTypes.SET_OPERATION_TYPE,
  payload: { value },
});

const setRevertState = (revertState: boolean): SetRevertStateAction => ({
  type: ActionTypes.SET_REVERT_STATE,
  payload: { revertState },
});

const setAmount = (payload: SetAmountPayload): SetAmountAction => ({
  type: ActionTypes.SET_AMOUNT,
  payload,
});

const setIsCopyComponent = (payload: SetIsCopyPayload): SetIsCopyAction => ({
  type: ActionTypes.SET_IS_COPY_COMPONENT,
  payload,
});

const setSelectedTags = (
  payload: SetSelectedTagsPayload,
): SetSelectedTagsAction => ({
  type: ActionTypes.SET_SELECTED_TAGS,
  payload,
});

const setCurrencyValue = (
  payload: SetCurrencyValuePayload,
): SetCurrencyValueAction => ({
  type: ActionTypes.SET_CURRENCY_VALUE,
  payload,
});

const setAccount = (payload: SetAccountPayload): SetAccountAction => ({
  type: ActionTypes.SET_ACCOUNT,
  payload,
});

const setExchangeRate = (
  payload: SetExchangeRatePayload,
): SetExchangeRateAction => ({
  type: ActionTypes.SET_EXCHANGE_RATE,
  payload,
});

const setRevertedExchangeRate = (
  payload: SetRevertExchangeRatePayload,
): SetRevertedExchangeRateAction => ({
  type: ActionTypes.SET_REVERTED_EXCHANGE_RATE,
  payload,
});

const setSplitPaymentProjects = (
  payload: SetSplitPaymentProjectsPayload,
): SetSplitPaymentProjectsAction => ({
  type: ActionTypes.SET_SPLIT_PAYMENT_PROJECTS,
  payload,
});

const setDateOfPayment = (dateOfPayment: number) => ({
  type: ActionTypes.SET_DATE_OF_PAYMENT,
  payload: { dateOfPayment },
});

const setComments = (payload: SetCommentsPayload): SetCommentsAction => ({
  type: ActionTypes.SET_COMMENTS,
  payload,
});

const setRepeats = (payload: SetRepeatsPayload): SetRepeatsAction => ({
  type: ActionTypes.SET_REPEATS,
  payload,
});

const setIncomeDate = (payload: SetIncomeDatePayload): SetIncomeDateAction => ({
  type: ActionTypes.SET_INCOME_DATE,
  payload,
});

const setStartTimeStamp = (
  payload: SetStartTimeStampPayload,
): SetStartTimeStampAction => ({
  type: ActionTypes.SET_START_TIME_STAMP,
  payload,
});

const setEndTimeStamp = (
  payload: SetEndTimeStampPayload,
): SetEndTimeStampAction => ({
  type: ActionTypes.SET_END_TIME_STAMP,
  payload,
});

const setSellDateWasChanged = (
  payload: SetDateOfPaymentWasChangedPayload,
): SetDateOfPaymentWasChangedAction => ({
  type: ActionTypes.SET_DATE_OF_PAYMENT_WAS_CHANGED,
  payload,
});

const setEditOperationId = (
  payload: SetEditOperationIdPayload,
): SetEditOperationIdAction => ({
  type: ActionTypes.SET_EDIT_OPERATION_ID,
  payload,
});

const setToAccount = (payload: SetToAccountPayload): SetToAccountAction => ({
  type: ActionTypes.SET_TO_ACCOUNT,
  payload,
});

const setCurrencyAmount = (
  payload: SetCurrencyAmountPayload,
): SetCurrencyAmountAction => ({
  type: ActionTypes.SET_CURRENCY_AMOUNT,
  payload,
});

const setSalaryPeriodId = (
  payload: SetSalaryPeriodIdPayload,
): SetSalaryPeriodIdAction => ({
  type: ActionTypes.SET_SALARY_PERIOD_ID,
  payload,
});

const setTaxPeriodId = (
  payload: SetTaxPeriodIdPayload,
): SetTaxPeriodIdAction => ({
  type: ActionTypes.SET_TAX_PERIOD_ID,
  payload,
});

const setDateOfPaymentPeriodId = (
  dateOfPaymentPeriodId: CustomPeriodEnum | null,
) => ({
  type: ActionTypes.SET_DATE_OF_PAYMENT_PERIOD_ID,
  payload: { dateOfPaymentPeriodId },
});

const setCreateAndCopyState = (
  payload: SetCreateAndCopyStatePayload,
): SetCreateAndCopyStateAction => ({
  type: ActionTypes.SET_CREATE_AND_COPY_STATE,
  payload,
});

const getOperationItems = (payload: GetOperationsItemsPayload) => ({
  type: ActionTypes.GET_CONTEXT_OPERATIONS_ITEMS,
  payload,
});

const getSettingsCategories = () => ({
  type: ActionTypes.GET_SETTINGS_CATEGORIES,
});

const setOperationProps = (operationProps: SetOperationPropsPayload) => ({
  type: ActionTypes.SET_OPERATION_PROPS,
  payload: operationProps,
});

const updateOneOperation = (
  data: UpdateOneOperationPayload,
): UpdateOneOperationAction => ({
  type: UPDATE_ONE_OPERATION_PENDING,
  payload: data,
});

const createOperation = (data: CreateOperationPayload) => ({
  type: CREATE_OPERATION_PENDING,
  payload: data,
});

const getExchangeRateFromTo = (data: GetExchangeRateFromToPayload) => ({
  type: ActionTypes.GET_EXCHANGE_RATE_FROM_TO_PENDING,
  payload: data,
});

const getExchangeRateToFrom = (data: GetExchangeRateFromToPayload) => ({
  type: ActionTypes.GET_EXCHANGE_RATE_TO_FROM_PENDING,
  payload: data,
});

const resetRates = () => ({
  type: RESET_RATES,
});

const updateOperation = (data: UpdateOperationPayload) => ({
  type: UPDATE_OPERATION_PENDING,
  payload: data,
});

const createTransferOperation = (data: CreateOperationPayload) => ({
  type: CREATE_TRANSFER_PENDING,
  payload: data,
});

const createClient = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.clients,
  },
});

const createInvestor = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.investors,
  },
});

const createCreditor = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.creditors,
  },
});

const createBorrower = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.borrowers,
  },
});

const createOwner = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.owners,
  },
});

const createTaxOrganisation = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.taxOrganisations,
  },
});

const createSupplier = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.suppliers,
  },
});

const createStaffMember = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.staffMembers,
  },
});

const createCategory = (name: string) => ({
  type: categoryActionTypes.CREATE_OPERATION_INCOME_PENDING,
  payload: { name },
});

const createConsumptionCategories = (name: string) => ({
  type: categoryActionTypes.CREATE_OPERATION_CONSUMPTION_PENDING,
  payload: { name },
});

const createProject = (name: string) => ({
  type: CREATE_OPERATION_PROJECTS_PENDING,
  payload: { name },
});

const createTag = (label: string) => ({
  type: CREATE_OPERATION_TAGS_PENDING,
  payload: { label },
});

const removeOperation = (data: RemoveOperationsPayload) => ({
  type: REMOVE_OPERATION_PENDING,
  payload: data,
});

const updateGroupCategory = (data: UpdateGroupCategoryPayload) => ({
  type: UPDATE_GROUP_CATEGORY,
  payload: data,
});

const updateGroupProject = (data: UpdateGroupProjectPayload) => ({
  type: UPDATE_GROUP_PROJECT,
  payload: data,
});

const updateGroupComment = (data: UpdateGroupCommentPayload) => ({
  type: UPDATE_GROUP_COMMENT,
  payload: data,
});

const updateGroupTag = (data: UpdateGroupTagPayload) => ({
  type: UPDATE_GROUP_TAG,
  payload: data,
});

const updateGroupClient = (data: UpdateGroupClientPayload) => ({
  type: UPDATE_GROUP_CLIENT,
  payload: data,
});

const updateGroupApprove = (data: UpdateGroupApprovePayload) => ({
  type: UPDATE_GROUP_APPROVE,
  payload: data,
});

const setAttachment = (attachment: Attachment | null) => ({
  type: ActionTypes.SET_ATTACHMENT,
  payload: { attachment },
});

const setAttachments = (attachments: Attachment[]) => ({
  type: ActionTypes.SET_ATTACHMENTS,
  payload: { attachments },
});

const updateGroupAccount = (data: UpdateGroupAccountPayload) => ({
  type: UPDATE_GROUP_ACCOUNT,
  payload: data,
});

const mergeToTransfer = (data: MergeOperationsToTransferPayload) => ({
  type: MERGE_OPERATIONS_TO_TRANSFER,
  payload: data,
});

const payOffFuture = (data: PayOffFuturePayload) => ({
  type: PAY_OFF_FUTURE,
  payload: data,
});

const mergeFuturePredictions = (data: MergeFuturePredictionsPayload) => ({
  type: MERGE_PREDICTIONS_FUTURE_OPERATIONS,
  payload: data,
});

const deleteFuturePrediction = (data: DeleteFuturePredictionPayload) => ({
  type: DELETE_PREDICTION_FUTURE_OPERATIONS,
  payload: data,
});

const getClientByPrefix = (prefix: UrlPrefix) => ({
  type: types.GET_CLIENTS_BY_TYPE_PENDING_OPERATIONS,
  payload: { prefix },
});

const resetClients = () => ({
  type: types.RESET_CLIENTS,
});

const updateByLocation = (location: any) => ({
  type: UPDATE_BY_LOCATION,
  payload: { location },
});

const updateType = (type: OperationType) => ({
  type: ActionTypes.UPDATE_TYPE,
  payload: { type },
});

const operationToTransfer = (data: OperationToTransferPayload) => ({
  type: OPERATION_TO_TRANSFER,
  payload: data,
});

const setScheduled = (data: SetScheduledPayload) => ({
  type: ActionTypes.SET_SCHEDULED,
  payload: data,
});

export default {
  setScheduled,
  updateType,
  setRevertedExchangeRate,
  resetClients,
  getClientByPrefix,
  mergeToTransfer,
  createSupplier,
  createConsumptionCategories,
  createTag,
  createOwner,
  createProject,
  createInvestor,
  createStaffMember,
  createBorrower,
  createCreditor,
  createTaxOrganisation,
  updateOneOperation,
  createOperation,
  getSettingsCategories,
  setExchangeRate,
  setRevertState,
  setToAccount,
  setAmount,
  setIsCopyComponent,
  setSelectedTags,
  setCurrencyValue,
  setAccount,
  setSplitPaymentProjects,
  setDateOfPayment,
  setComments,
  setRepeats,
  setIncomeDate,
  setSalaryPeriodId,
  setSellDateWasChanged,
  setStartTimeStamp,
  setEditOperationId,
  setEndTimeStamp,
  resetOperationProps,
  getOperationItems,
  setOperationProps,
  setTaxPeriodId,
  setDateOfPaymentPeriodId,
  setCreateAndCopyState,
  setCurrencyAmount,
  getExchangeRateFromTo,
  resetRates,
  updateOperation,
  createTransferOperation,
  createCategory,
  createClient,
  removeOperation,
  updateGroupCategory,
  updateGroupProject,
  updateGroupComment,
  updateGroupTag,
  updateGroupClient,
  updateGroupApprove,
  setAttachment,
  setAttachments,
  updateGroupAccount,
  mergeFuturePredictions,
  getExchangeRateToFrom,
  updateByLocation,
  deleteFuturePrediction,
  setOperationType,
  operationToTransfer,
  setPeriodDateOfPayment,
  updateGroupOperations,
  payOffFuture,
};
