import { makeStyles } from '@material-ui/core';

import { StyleProps } from '../../foundation/Operations/Components/ConfirmChangeRepeatDialog/types';
import { aquaHaze, jade } from '../../theme/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: (props: StyleProps) => (!props.multiline ? 56 : 'inherit'),
    position: 'relative',
    backgroundColor: aquaHaze,
    marginBottom: (props: StyleProps) => (props.isError ? 28 : 16),
    borderRadius: 12,
    boxSizing: 'border-box',
    transition: 'margin 700ms',
    '&:focus-within': {
      border: (props: StyleProps) =>
        props.isError ? 'none' : `1px solid ${jade}`,
    },
    '&:nth-last-child': {
      marginBottom: 42,
    },
  },
  error: {
    background: 'rgba(255, 63, 46, 0.12)',
  },
  container: {
    flex: 1,
    padding: '9px 16px',
  },
  textRoot: {
    flex: 1,
    minHeight: (props: StyleProps) => props.height,
  },
  inputRoot: {
    flex: 1,
    alignItems: 'baseline',
  },
  filterComment: {
    padding: '10px 20px 10px 45px',
  },
  inputWithError: {
    marginBottom: 10,
  },
  icon: {
    top: '30%',
    left: '2%',
    position: 'absolute',
  },
  hint: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
  },
  fullOpacity: {
    opacity: '1 !important',
  },
  textEllipsis: {
    textOverflow: 'ellipsis',
  },
});
