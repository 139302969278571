import { Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import * as Yup from 'yup';

import { ONBOARDING_SUB_STEPS } from '@finmap/core-constants';
import { normalize } from '@finmap/core-utils';

import InformerIcon from '../../../assets/images/svg/attention-gray.svg';
import AutocompleteSelector from '../../../components/AutocompleteSelector';
import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import { Ga4Errors } from '../../../components/TextField/TextFieldComponent.types';
import { DEFAULT_UKRAINE_CURRENCY_SYMBOL } from '../../../constants';
import countriesData from '../../../constants/countriesV2.json';
import { HelperPages } from '../../../hooks/useHelpVideoLinks';
import useOnboardingV2 from '../../../hooks/useOnboardingV2';
import accountActions from '../../../store/accounts/actions';
import { selectSettingsAccounts } from '../../../store/accounts/selectors';
import authServices from '../../../store/auth/api';
import { getUserGeoData } from '../../../store/common/selectors';
import { selectCurrentCompany } from '../../../store/company/selectors';
import {
  selectCryptoCurrenciesAutoComplete,
  selectCurrenciesAutoComplete,
} from '../../../store/currency/selectors';
import { AutoCompleteProps } from '../../../store/types';
import { formatStringPrice } from '../../../utils/parseStringToNumber';
import VideoHelper from '../../VideoHelper';
import { useStyles } from './styles';
import { Props } from './types';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('errors.reqField'),
});

function CreateAccount(props: Props) {
  const { onClose } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const currencies = useSelector(selectCurrenciesAutoComplete);
  const cryptoCurrencies = useSelector(selectCryptoCurrenciesAutoComplete);
  const accounts = useSelector(selectSettingsAccounts);
  const currentCompany = useSelector(selectCurrentCompany);
  const userGeoData = useSelector(getUserGeoData);

  const { showNewMessages } = useIntercom();

  const [currency, setCurrency] = useState<AutoCompleteProps<{
    isCrypto?: boolean;
  }> | null>(null);
  const [error, setError] = useState('');
  const [loadingCurrencies, setLoadingCurrencies] = useState(false);

  const [accountName, setAccountName] = useState('');
  const [balance, setBalance] = useState('');

  const { updateIntegrationStep, isIntegrationActiveStep } = useOnboardingV2();

  const openIntercom = useCallback(() => {
    showNewMessages(t('currency.writeUs'));
  }, [showNewMessages, t]);

  const defaultCurrency = useMemo(() => {
    const currentCurrency = currencies.find(
      (cur) => cur.id === currentCompany?.currencyId,
    );

    if (currentCurrency) {
      return {
        id: currentCurrency.id,
        name: t(`currency.${currentCurrency.id}`),
        label: t(`currency.${currentCurrency.id}`),
      };
    }

    return {
      id: '',
      name: '',
      label: '',
    };
  }, [currencies, currentCompany?.currencyId, t]);

  const validateFields = useCallback(
    async () =>
      validationSchema
        .validate({ name: accountName })
        .then(() => true)
        .catch((err) => {
          if (err.path === 'name') {
            setError(t(err.errors));
          }

          return false;
        }),
    [accountName, t],
  );

  const handleChangeCurrency = useCallback((value: AutoCompleteProps) => {
    setCurrency(value);
  }, []);

  const handleChangeBalance = useCallback(
    (value: string | number) => {
      const balanceDigits = currency?.isCrypto ? 9 : 3;

      const price = formatStringPrice(value, true, balanceDigits);

      setBalance(price);
    },
    [currency],
  );

  const handleChangeName = useCallback((value: string) => {
    setError('');
    setAccountName(value);
  }, []);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const onCreate = useCallback(
    (currencyId: string, isCrypto: boolean) => {
      dispatch(
        accountActions.createAccount({
          name: accountName,
          currencyId,
          balance: Number(balance),
          isCrypto,
        }),
      );

      if (isIntegrationActiveStep) {
        updateIntegrationStep(ONBOARDING_SUB_STEPS.INTEGRATION_ADD_OPERATION);

        onClose(false);
      } else {
        onClose(true);
      }
    },
    [
      onClose,
      balance,
      dispatch,
      accountName,
      updateIntegrationStep,
      isIntegrationActiveStep,
    ],
  );

  const handleCreate = useCallback(async () => {
    if (await validateFields()) {
      const isAccountExist = accounts.some(
        (el) => el.normalizedLabel === normalize(accountName),
      );

      if (isAccountExist) {
        setError(t('errors.create.account.exist'));

        return;
      }

      if (currency) {
        onCreate(currency.id, !!currency.isCrypto);

        setBalance('');
        setAccountName('');
        setCurrency(null);
      }
    }
  }, [t, accountName, accounts, onCreate, currency, validateFields]);

  useEffect(() => {
    const getLocationByIpInfo = async () => {
      try {
        setLoadingCurrencies(true);

        if (!userGeoData) {
          return;
        }

        if (!userGeoData.fromCache) {
          await authServices.updateUserCountry(userGeoData.country);
        }

        const countryCode = userGeoData.country;
        const country = countriesData.find(
          (el) => el.alpha2Code === countryCode,
        );

        const currencySymbol = country
          ? country.currencies[0].code
          : DEFAULT_UKRAINE_CURRENCY_SYMBOL;
        const userDefaultCurrencyByLocation = currencies.find(
          (el) => el.id === currencySymbol,
        );

        if (userDefaultCurrencyByLocation) {
          setCurrency(userDefaultCurrencyByLocation);
        } else {
          setCurrency(defaultCurrency);
        }
      } catch (e) {
        setCurrency(defaultCurrency);
      }

      setLoadingCurrencies(false);
    };

    getLocationByIpInfo();
  }, [defaultCurrency, currencies, userGeoData]);

  useEffect(
    () => () => {
      setBalance('');
      setAccountName('');
      setCurrency(defaultCurrency);
    },
    [defaultCurrency],
  );

  return (
    <Dialog
      isOpened
      onClose={handleClose}
      title={t('accounts.adding')}
      className={classes.dialog}
    >
      <VideoHelper page={HelperPages.newAccount} />
      <div className={classes.separator} />
      <AutocompleteSelector
        id="cypress-currency-selector"
        label={t('common.currency')}
        value={currency}
        onChange={handleChangeCurrency}
        data={[...currencies, ...cryptoCurrencies]}
        loading={loadingCurrencies}
        lastRow="currency.addCryptoCurrency"
        lastRowAction={openIntercom}
      />
      <>
        <TextFieldComponent
          ga4Id={Ga4Errors.createAccountNameError}
          id="cypress-create-account-name"
          onChange={handleChangeName}
          value={accountName}
          placeholder={t('common.title')}
          isError={!!error}
          errorText={error}
        />
      </>

      <div className={classes.lastBlock}>
        <TextFieldComponent
          onChange={handleChangeBalance}
          value={balance}
          label={balance ? t('settingsPage.startBalance') : ''}
          placeholder={t('settingsPage.startBalance')}
        />
      </div>
      <div className={classes.informerContainer}>
        <img src={InformerIcon} alt="info" className={classes.informerIcon} />
        <Typography className={classes.informerText}>
          {t('informers.newAccountAmount')}
        </Typography>
      </div>
      <CustomButton
        id="cypress-confirm-create-button"
        fullWidth
        action={handleCreate}
        title={t('accounts.add')}
      />
    </Dialog>
  );
}

export default React.memo(CreateAccount);
