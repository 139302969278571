import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class DniproParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                eq: '№',
              },
            ],
          ],
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Дата документа', 1],
                dateFormat: 'MM/DD/YY',
              },
              comment: {
                column: ['Назначение платежа', 6],
              },
              counterparty: {
                column: ['Название', 4],
              },
              debit: {
                column: ['Дт', 2],
              },
              credit: {
                column: ['Кт', 3],
              },
            },
          },
        },
      },
    ],
  };
}
