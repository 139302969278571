import Http from '../../services/Http';
import { Context } from '../types';
import { Category } from './types';

const getIncomeCategories = (context: Context) =>
  Http.makeInstance().get(`/categories/income?context=${context}`);

const createIncomeCategory = (
  label: string,
  context: Context,
  parentId?: string,
) =>
  Http.makeInstance().post(`/categories/income?context=${context}`, {
    label,
    parentId,
  });

const updateIncomeCategory = (category: Category) =>
  Http.makeInstance().patch(
    `/categories/income/${category._id}?isBulkUpdate=true`,
    {
      ...category,
    },
  );

const updateAllIncomeCategories = (categories: Category[]) =>
  Http.makeInstance().patch(
    `/categories/income/update/many?isBulkUpdate=true`,
    {
      categories,
    },
  );

const deleteIncomeCategory = (id: string) =>
  Http.makeInstance().delete(`/categories/income/${id}`);

const getConsumptionCategories = (context: Context) =>
  Http.makeInstance().get(`/categories/consumption?context=${context}`);

const createConsumptionCategory = (
  label: string,
  context: Context,
  parentId?: string,
) =>
  Http.makeInstance().post(`/categories/consumption?context=${context}`, {
    label,
    parentId,
  });

const updateConsumptionCategory = (category: Category) =>
  Http.makeInstance().patch(`/categories/consumption/${category._id}`, {
    ...category,
  });

const updateAllConsumptionCategories = (categories: Category[]) =>
  Http.makeInstance().patch(`/categories/consumption/update/many`, {
    categories,
  });

const deleteConsumptionCategory = (id: string) =>
  Http.makeInstance().delete(`/categories/consumption/${id}`);

export default {
  getIncomeCategories,
  createIncomeCategory,
  updateIncomeCategory,
  deleteIncomeCategory,
  getConsumptionCategories,
  createConsumptionCategory,
  updateConsumptionCategory,
  deleteConsumptionCategory,
  updateAllIncomeCategories,
  updateAllConsumptionCategories,
};
