import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class BglParibasParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                eq: 'Posting Date',
              },
            ],
          ],
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Posting Date', 0],
                dateFormat: 'DD/MM/YYYY',
              },
              comment: {
                column: ['Comment', 2],
              },
              sum: {
                column: ['Amount', 3],
              },
            },
          },
        },
      },
    ],
  };
}
