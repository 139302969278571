import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class KuveytParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'TL',
          isCurCase: [
            [
              0,
              {
                eq: 'Account',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'top',
            pageSeparatorsLengthPx: 40,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Transaction',
                    },
                    nexWord: {
                      eq: 'Description',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'Date',
                    },
                    prevWord: {
                      eq: 'Reference Number',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Transaction',
                      },
                      nexWord: {
                        eq: 'Description',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  isNum: true,
                  replace: [
                    {
                      from: ',',
                      to: '',
                    },
                  ],
                },
                {
                  in: '.',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Transaction\nDate', 0],
                dateFormat: 'MM/DD/YYYY',
              },
              comment: {
                column: ['Description', 1],
                add: [
                  ' ',
                  {
                    column: ['Reference Number', 4],
                  },
                ],
              },
              sum: {
                column: ['Amount', 2],
                replace: [
                  {
                    from: ',',
                    to: '',
                  },
                ],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'TL',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Account',
              },
            ],
          ],
          proceedCaseConfig: {
            withoutEmpty: true,
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Transaction Date',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Description',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              sum: {
                column: ['Amount', 2],
              },
              date: {
                column: ['Transaction Date', 0],
                dateFormat: 'YYYY-MM-DD',
              },
              comment: {
                column: ['Description', 1],
                add: [
                  ' ',
                  {
                    column: ['Reference Number', 4],
                  },
                ],
              },
            },
          },
        },
      },
    ],
  };
}
