import { Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import PlayIcon from '../../assets/images/svg/play_circle_green.svg';
import VideoDialog from '../../components/VideoDialog';
import useHelpVideoLinks, { HelperPages } from '../../hooks/useHelpVideoLinks';
import { useStyles } from './styles';
import './animation.css';
import useUnleashGetVariant from '../../hooks/useUnleashGetVariant';
import { FEATURES } from '@finmap/core-constants';
import { showError } from '../../utils/showError';

function VideoHelper({ page }: { page: HelperPages }) {
  const { getHelperProps, showVideoLinks } = useHelpVideoLinks();
  const { link, enabled, label, title } = getHelperProps(page);

  const showAnimatedVideoIcon = useUnleashGetVariant(
    FEATURES.SHOW_ANIMATED_VIDEO_ICON,
  );
  const classes = useStyles();

  const [showVideoDialog, setShowVideoDialog] = useState(false);

  const handleOpenVideoDialog = useCallback(() => {
    setShowVideoDialog(true);
  }, []);

  const handleCloseVideoDialog = useCallback(() => {
    setShowVideoDialog(false);
  }, []);

  useEffect(() => {
    if (showAnimatedVideoIcon) {
      const root = document.documentElement;
      const value = showAnimatedVideoIcon.payload?.value;

      if (value) {
        try {
          const payloadProps = JSON.parse(value);
          root.style.setProperty(
            '--iteration-count',
            `${payloadProps.iterationCount}s`,
          );
          root.style.setProperty(
            '--animation-duration',
            payloadProps.animationDuration,
          );
        } catch (e) {
          showError(e);
        }
      }
    }
  }, [showAnimatedVideoIcon]);

  return (
    <div>
      {showVideoLinks && enabled && (
        <div className={classes.helpRow} onClick={handleOpenVideoDialog}>
          <div className={showAnimatedVideoIcon ? 'icon-animated' : ''}>
            <img src={PlayIcon} alt="play" className={classes.playIcon} />
          </div>
          <Typography className={classes.helpText}>{label}</Typography>
        </div>
      )}
      <VideoDialog
        title={title}
        isOpened={showVideoDialog}
        videoLink={link}
        onCLose={handleCloseVideoDialog}
      />
    </div>
  );
}

export default React.memo(VideoHelper);
