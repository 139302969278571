import moment from 'moment';

import { findFirstMatch } from '@finmap/core-utils';
import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class OschadBankImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return (
            rawDocument?.length &&
            rawDocument[0].str.includes('Дата формування')
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(
            importDocument.slice(1).map((el) => {
              el[2] = el[2].replaceAll('\n', '');
              return el;
            }),
          );
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата\nпроведення', 2]),
            dateAndTime: findFirstMatch(
              this.getFirstValidCellByColumn(['Дата/час', 1]) || '',
              /\d{2}\.\d{2}\.\d{4}\s+\d{2}:\d{2}:\d{2}/,
            )
              ?.trim()
              ?.replace(/\s/, ' '),
            debit: this.getFirstValidCellByColumn([
              'ДТ рахунку',
              'ДТ\nрахунку',
            ]),
            credit: this.getFirstValidCellByColumn([
              'КТ рахунку',
              'КТ\nрахунку',
            ]),
            counterparty: this.getFirstValidCellByColumn([
              'Назва контрагента',
              'Назва\nКод',
              5,
            ]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 9]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isDESCOpOrder: true,
          preParserConfigs: {
            interlineSpacingAccuracy: 10,
            separatorsDistanceModifier: 0,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  (word?.includes('Дата') && etc?.nextWord?.includes('Код')) ||
                  etc?.prevWord?.includes('Реквізити контрагента'),
                (word, etc) =>
                  (word?.includes('МФО') && etc?.prevWord?.includes('Код')) ||
                  (word?.includes('контрангента') &&
                    !/^(контрангента)$/.test(etc.nextWord)),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  (word?.includes('МФО') && etc?.prevWord?.includes('Код')) ||
                  (word?.includes('контрангента') &&
                    !/^(контрангента)$/.test(etc.nextWord)),
                1,
              );
              self.deleteFromTo(
                (word) => word?.includes('Фактичний платник:'),
                (word) => word?.includes('Фактичний отримувач:'),
              );
              self.defineOperation([
                (value) => /^(-?[\s\d]+,(\d{2})?)$/.test(value), // '1 000,00'
              ]);
            },
          },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn(['Дата', 0, 3])?.split(
              '\n',
            )[0],
            comment: this.getFirstValidCellByColumn(['Деталі транзакції', 1]),
            sum: this.getFirstValidCellByColumn(
              ['Сума', 6, 2],
              this.isImportValueValid,
              { revertSearch: true },
            )
              ?.split('\n')[0]
              ?.replace(/[^0-9.-]/, '')
              ?.trim(),
            currency: this.getFirstValidCellByColumn(['Валюта', 3]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isDESCOpOrder: true,
          preParserConfigs: {
            interlineSpacingAccuracy: 10,
            separatorsDistanceModifier: 1.25,
            verticalAlign: 'top',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата') && etc?.prevWord?.includes('Рахунок'),
                (word, etc) =>
                  word?.includes('Сума') && etc?.prevWord?.includes('Комісія'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  etc.prevWord?.includes('Комісія') && word?.includes('Сума'),
                1,
              );
              self.deleteFromTo(
                (word) => word?.startsWith('Всього'),
                (word, etc) =>
                  word?.includes('Сума') && etc.prevWord?.includes('Комісія'),
              );
              self.deleteFromTo(
                (word) => word?.startsWith('Всьго по валюті'),
                (word) => word?.startsWith('Вал.'),
              );
              self.deleteFromTo(
                (word) => word?.startsWith('Page'),
                (word) => word?.startsWith('Сума'),
              );
              self.defineOperation([
                (value) => /^-*([\s\d]+\.\d+)$/.test(value), // '-22 200.00'
                (value) =>
                  moment(value, 'DD/MM/YY HH:mm', true).isValid() ||
                  moment(value, 'DD/MM/YY', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        proceedCase: (importDocument) => {
          const firstHeaderLine = this.findString(/^№ док-та/)?.raw;
          const rawBody = importDocument.slice(firstHeaderLine + 1);
          const regex = /^[^\u0400-\u04FF]*$/; // exclude кирилиця
          const body = rawBody.filter(([n]) => !!n && regex.test(n));
          this.setDocumentHeader(importDocument[firstHeaderLine]);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата проведення', 2]),
            dateAndTime: findFirstMatch(
              this.getFirstValidCellByColumn(['Дата/час', 1]) || '',
              /\d{2}\.\d{2}\.\d{4}\s+\d{2}:\d{2}:\d{2}/,
            )
              ?.trim()
              ?.replace(/\s/, ' '),
            debit: this.getFirstValidCellByColumn(['ДТ рахунку']),
            credit: this.getFirstValidCellByColumn(['КТ рахунку']),
            counterparty: this.getFirstValidCellByColumn([
              'Назва контрагента',
              'Реквізити контрагента',
              5,
            ]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 9]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH', isDESCOpOrder: true },
      },
    ],
  };
}
