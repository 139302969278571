import { Typography, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { AxiosResponse } from 'axios';
import cn from 'classnames';
import moment from 'moment/moment';
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { normalize } from '@finmap/core-utils';
import { FEATURES } from '@finmap/core-constants';

import ConnectionSafeIcon from '../../../assets/images/svg/connectionSafe.svg';
import FacturowniaLink from '../../../assets/images/svg/payments/facturownia.svg';
import iDokladLink from '../../../assets/images/svg/payments/iDoklad.png';
import PayoneerLink from '../../../assets/images/svg/payments/payoneer.svg';
import PosterLink from '../../../assets/images/svg/payments/poster.png';
import Loader from '../../../components/Loader/LottieLoader';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import {
  DEFAULT_COUNTRY,
  PAYPAL_UNIVERSAL_CODE,
  PAYPAL_UNIVERSAL_NAME,
  WISE_UNIVERSAL_CODE,
  WISE_UNIVERSAL_NAME,
} from '../../../constants';
import useOnboardingV2 from '../../../hooks/useOnboardingV2';
import useUnleash from '../../../hooks/useUnleash';
import OnboardingNoAvailable from '../../../scenes/InfoBlock/OnBoardingV2/Components/OnboardingNoAvailable';
import TooltipWrapper from '../../../scenes/InfoBlock/OnBoardingV2/Components/TooltipWrapper';
import { useTooltipStyles } from '../../../scenes/InfoBlock/OnBoardingV2/Components/TooltipWrapper/styles';
import nordigenApi from '../../../services/bankIntegrations/nordigen';
import belvoApi from '../../../services/bankIntegrations/belvo';
import saltedgeApi from '../../../services/bankIntegrations/saltedge';
import { selectCountIntegrations } from '../../../store/accounts/selectors';
import authServices from '../../../store/auth/api';
import { getUserGeoData } from '../../../store/common/selectors';
import { selectCurrentCompany } from '../../../store/company/selectors';
import {
  BelvoBankType,
  IntegrationTypeId,
  NordigenBankType,
  SaltedgeBankType,
} from '../../../store/integrations/types';
import { minOperationDate } from '../../../utils/dateFormat';
import { sortByName } from '../../../utils/strings';
import { isLite } from '../../../utils/subscriptions';
import SubscriptionDialog from '../../Subscriptions/SubscriptionChooseDialog';
import AdditionalBanks from './AdditionalBanks';
import CountriesDialog, { belvoCountries } from './CountriesDialog';
import {
  defaultNordigenBanks,
  paypalNordigenBank,
  revoluteNordigenBank,
  stripeNordigenBank,
  wiseNordigenBank,
} from './defaultNordigenBanks';
import { defaultSaltedgeBanks } from './defaultSaltedgeBanks';
import ServiceProviderBanks from './ServiceProviderBanks';
import { useStyles } from './styles';
import { testSaltedgeBanks } from './testSaltedgeBanks';
import { Props } from './types';
import UkraineBanks from './UkraineBanks';

let timeout: NodeJS.Timeout | null = null;

function RenderBanksList(props: Props) {
  const {
    showFondy,
    description,
    onSetupBank,
    selectedAccountId,
    showOnboardingTooltips,
    onShowOnboardingTooltips,
    onCloseOnboardingTooltips,
    onClose,
  } = props;

  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const { t } = useTranslation();

  const { onCreateDemoData, isCreateAccountActiveStep } = useOnboardingV2();

  const company = useSelector(selectCurrentCompany);
  const countIntegrations = useSelector(selectCountIntegrations);
  const userGeoData = useSelector(getUserGeoData);

  const showTestSaltedgeBanks = useUnleash(FEATURES.SHOW_TEST_SALTEDGE_BANKS);
  const useOnlyNordigenService = useUnleash(FEATURES.USE_ONLY_NORDIGEN_SERVICE);
  const showFacturowniaIntegration = useUnleash(
    FEATURES.SHOW_FACTUROWNIA_INTEGRATION,
  );

  const smallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(1200),
  );

  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  const [loadingBankList, setLoadingBankList] = useState(true);
  const [currentCountryCode, setCurrentCountryCode] = useState('');
  const [bankList, setBankList] = useState<SaltedgeBankType[]>([]);
  const [filteredBanks, setFilteredBanks] = useState<SaltedgeBankType[]>([]);
  const [showCountriesDialog, setShowCountriesDialog] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [showNoAvailableDialog, setShowNoAvailableDialog] = useState(false);

  let banksList: NordigenBankType[] | SaltedgeBankType[] = useMemo(
    () =>
      showTestSaltedgeBanks
        ? defaultSaltedgeBanks.concat(testSaltedgeBanks)
        : defaultSaltedgeBanks,
    [showTestSaltedgeBanks],
  );

  if (useOnlyNordigenService) {
    banksList = defaultNordigenBanks;
  }

  const handleCreateDemoData = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      onCreateDemoData();
    },
    [onCreateDemoData],
  );

  const handleCloseNoAvailableDialog = useCallback(() => {
    setShowNoAvailableDialog(false);
  }, []);

  const handleOpenSubscriptionDialog = useCallback(() => {
    setShowSubscriptionDialog(true);
  }, []);

  const handleCloseSubscriptionDialog = useCallback(() => {
    setShowSubscriptionDialog(false);
  }, []);

  const handleSetupUkrBank = useCallback(
    (event: SyntheticEvent) => {
      // @ts-ignore
      const { bankid } = event.currentTarget.dataset;

      if (isLite(company) && countIntegrations === 2) {
        handleOpenSubscriptionDialog();

        return;
      }

      if (onCloseOnboardingTooltips) {
        onCloseOnboardingTooltips();
      }

      onSetupBank(bankid);
    },
    [
      company,
      onSetupBank,
      countIntegrations,
      onCloseOnboardingTooltips,
      handleOpenSubscriptionDialog,
    ],
  );

  const handleSetShowTooltip = useCallback(
    (value: boolean) => {
      if (!onShowOnboardingTooltips || !onCloseOnboardingTooltips) {
        return;
      }

      if (value) {
        onShowOnboardingTooltips();
      } else {
        onCloseOnboardingTooltips();
      }
    },
    [onShowOnboardingTooltips, onCloseOnboardingTooltips],
  );

  const handleOpenCountriesDialog = useCallback(() => {
    setShowCountriesDialog(true);

    if (onCloseOnboardingTooltips) {
      onCloseOnboardingTooltips();
    }
  }, [onCloseOnboardingTooltips]);

  const handleCloseCountriesDialog = useCallback(() => {
    setShowCountriesDialog(false);

    if (onShowOnboardingTooltips) {
      onShowOnboardingTooltips();
    }
  }, [onShowOnboardingTooltips]);

  const handleSearch = useCallback(
    (value: string) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      setSearchValue(value);

      timeout = setTimeout(() => {
        if (value) {
          const filtered = bankList.filter(
            (el) => normalize(el.name).indexOf(normalize(value)) !== -1,
          );

          setFilteredBanks(filtered);
        } else {
          setFilteredBanks(bankList);
        }
      }, 500);
    },
    [bankList],
  );

  useEffect(() => {
    if (userGeoData) {
      if (!userGeoData.fromCache) {
        authServices.updateUserCountry(userGeoData.country);
      }
      setCurrentCountryCode(userGeoData.country);
    } else {
      setCurrentCountryCode(DEFAULT_COUNTRY);
    }
  }, [userGeoData]);

  useEffect(() => {
    const getBanksList = async () => {
      try {
        let data: SaltedgeBankType[] | NordigenBankType[] | BelvoBankType[] =
          [];
        if (belvoCountries.includes(currentCountryCode)) {
          const resp: AxiosResponse<BelvoBankType[]> =
            await belvoApi.getAllBanks(currentCountryCode);
          data = resp.data.map((el) => ({ ...el, isBelvo: true }));
        } else {
          const resp: AxiosResponse<SaltedgeBankType[] | NordigenBankType[]> =
            useOnlyNordigenService
              ? await nordigenApi.getAllBanks(currentCountryCode)
              : await saltedgeApi.getAllBanks(currentCountryCode);
          data = resp.data;
        }

        return data
          .map((el) => {
            if (el.name === WISE_UNIVERSAL_NAME) {
              return { ...el, code: WISE_UNIVERSAL_CODE };
            }

            if (el.name === PAYPAL_UNIVERSAL_NAME) {
              return { ...el, code: PAYPAL_UNIVERSAL_CODE };
            }

            return el;
          })
          .filter((el) => el.code !== 'ing_oauth_client_pl');
      } catch (e) {
        return [];
      }
    };

    if (currentCountryCode) {
      const currentCountryBanksList = getBanksList();
      const maxTakingDaysNumber = moment.utc().diff(minOperationDate(), 'days');

      currentCountryBanksList.then((list) => {
        if (
          normalize(currentCountryCode) === 'in' ||
          normalize(currentCountryCode) === 'za'
        ) {
          // @ts-ignore
          list.push(stripeNordigenBank);
          // @ts-ignore
          list.push(wiseNordigenBank);
          // @ts-ignore
          list.push(paypalNordigenBank);
        }

        if (normalize(currentCountryCode) === 'kz') {
          // @ts-ignore
          list.push(revoluteNordigenBank);
          // @ts-ignore
          list.push(paypalNordigenBank);
          // @ts-ignore
          list.push(wiseNordigenBank);
          // @ts-ignore
          list.push(stripeNordigenBank);
          // @ts-ignore
          list.push({
            name: 'Poster',
            id: 'Poster',
            logo_url: PosterLink,
            customHandler: onSetupBank,
            maxTakingDaysNumber,
            max_consent_days: maxTakingDaysNumber,
          });
        }

        if (
          selectedAccountId &&
          showFacturowniaIntegration &&
          normalize(currentCountryCode) === 'pl'
        ) {
          // @ts-ignore
          list.push({
            name: 'Fakturownia',
            id: 'Fakturownia',
            integrationTypeId: IntegrationTypeId.Facturownia,
            logo_url: FacturowniaLink,
            customHandler: onSetupBank,
          });
        }

        if (selectedAccountId && normalize(currentCountryCode) === 'cz') {
          // @ts-ignore
          list.push({
            name: 'iDoklad',
            id: 'iDoklad',
            integrationTypeId: IntegrationTypeId.iDoklad,
            logo_url: iDokladLink,
            customHandler: onSetupBank,
          });
        }

        if (normalize(currentCountryCode) !== 'ua') {
          // @ts-ignore
          list.push({
            name: 'Payoneer',
            id: 'Payoneer',
            integrationTypeId: IntegrationTypeId.Payoneer,
            logo_url: PayoneerLink,
            customHandler: onSetupBank,
          });
        }

        if (normalize(currentCountryCode) === 'uz') {
          // @ts-ignore
          list.push(revoluteNordigenBank);
          // @ts-ignore
          list.push(paypalNordigenBank);
          // @ts-ignore
          list.push(wiseNordigenBank);
          // @ts-ignore
          list.push(stripeNordigenBank);
          // @ts-ignore
          list.push({
            name: 'Poster',
            id: 'Poster',
            logo_url: PosterLink,
            customHandler: onSetupBank,
            maxTakingDaysNumber,
            max_consent_days: maxTakingDaysNumber,
          });
        }

        list.sort(sortByName);
        // @ts-ignore
        setBankList(list);
        // @ts-ignore
        setFilteredBanks(list);
        setLoadingBankList(false);
      });
    }
  }, [
    onSetupBank,
    selectedAccountId,
    currentCountryCode,
    useOnlyNordigenService,
    showFacturowniaIntegration,
  ]);

  useEffect(() => {
    if (isCreateAccountActiveStep && onShowOnboardingTooltips) {
      onShowOnboardingTooltips();
    }
  }, [isCreateAccountActiveStep, onShowOnboardingTooltips]);

  if (loadingBankList) {
    return (
      <>
        <Typography className={classes.description}>{description}</Typography>
        <Loader size="small" />
      </>
    );
  }

  return (
    <>
      <div id="onboarding-container-bank">
        <TooltipWrapper
          title={
            <div>
              <Typography className={tooltipClasses.onboardingPopupTitle}>
                {t('onboardingV2.tooltip.noBank')}
              </Typography>
              <Typography className={tooltipClasses.onboardingPopupText}>
                {t('onboardingV2.tooltip.noBankDescription', {
                  postProcess: 'reactPostprocessor',
                  loadDemoData: (
                    <span
                      className={cn(
                        tooltipClasses.onboardingPopupTitle,
                        tooltipClasses.underline,
                        tooltipClasses.clickable,
                      )}
                      onClick={handleCreateDemoData}
                    >
                      {t('onboardingV2.tooltip.handleLoadDemoData')}
                    </span>
                  ),
                })}
              </Typography>
            </div>
          }
          open={!!showOnboardingTooltips}
          placement={smallScreen ? 'top' : 'left'}
        >
          <Typography className={classes.description}>{description}</Typography>
        </TooltipWrapper>
      </div>
      <div className={classes.safeInfoContainer}>
        <img src={ConnectionSafeIcon} alt="safe" className={classes.safeIcon} />
        <Typography>{t('integrations.connectionSafe')}</Typography>
      </div>
      <div className={classes.searchBlock}>
        {bankList.length > 10 && (
          <TextFieldComponent
            value={searchValue}
            onChange={handleSearch}
            rootClassName={classes.searchBlockInput}
            placeholder={t('bank.search')}
          />
        )}
      </div>
      <div className={classes.customBankContainer}>
        {!!filteredBanks.length && (
          <ServiceProviderBanks
            data={filteredBanks}
            countryCode={currentCountryCode}
            internalAccountId={selectedAccountId}
            onSetShowOnboardingTooltip={handleSetShowTooltip}
            onOpenSubscriptionDialog={handleOpenSubscriptionDialog}
            onCloseIntegrationsDialog={onClose}
          />
        )}

        {!bankList.length && (
          <>
            {normalize(currentCountryCode) === 'ua' && (
              <UkraineBanks onSetupBank={handleSetupUkrBank} />
            )}
            <AdditionalBanks
              onSetupBank={handleSetupUkrBank}
              showFondy={showFondy}
              showCheckBox={normalize(currentCountryCode) !== 'ru'}
              showPoster={normalize(currentCountryCode) === 'ua'}
            />
            <ServiceProviderBanks
              data={banksList}
              countryCode="GB"
              internalAccountId={selectedAccountId}
              onSetShowOnboardingTooltip={handleSetShowTooltip}
              onOpenSubscriptionDialog={handleOpenSubscriptionDialog}
              onCloseIntegrationsDialog={onClose}
            />
          </>
        )}
      </div>
      <TooltipWrapper
        open={!!showOnboardingTooltips}
        title={
          <Typography className={tooltipClasses.onboardingPopupTitle}>
            {t('onboardingV2.tooltip.correctGeo')}
          </Typography>
        }
        placement={smallScreen ? 'bottom' : 'left'}
      >
        <div
          id="other_bank_my_accounts"
          className={cn(
            classes.button,
            classes.fullWidthButton,
            classes.marginBottom48,
          )}
          onClick={handleOpenCountriesDialog}
        >
          <div className={classes.flex}>
            <Typography className={classes.bankText}>
              {t('bank.otherBank')}
            </Typography>
          </div>
        </div>
      </TooltipWrapper>

      {showSubscriptionDialog && (
        <SubscriptionDialog
          title={t('subscription.warnings.getMoreIntegrations')}
          onClose={handleCloseSubscriptionDialog}
        />
      )}

      {showCountriesDialog && (
        <CountriesDialog
          showFondy={showFondy}
          onSetupBank={onSetupBank}
          onCloseIntegrationsDialog={onClose}
          onClose={handleCloseCountriesDialog}
          onSetupUrkBank={handleSetupUkrBank}
          selectedAccountId={selectedAccountId}
          currentCountryCode={currentCountryCode}
          onOpenSubscriptionDialog={handleOpenSubscriptionDialog}
        />
      )}
      {showNoAvailableDialog && (
        <OnboardingNoAvailable onClose={handleCloseNoAvailableDialog} />
      )}
    </>
  );
}

export default React.memo(RenderBanksList);
